<div class="ds-rows">
  <p class="ds-notice --info">{{ 'security.auth_keys.description' | translate }}</p>

  <ui-button [content]="generateAuthKeyButton"></ui-button>

  <app-alerts [alertsManager]="alertManager"></app-alerts>

  <table class="ds-datatable --embedded --compact table-auth-key-list">
    <thead>
      <tr>
        <th>
          {{ 'security.user_details.auth.table.column.id' | translate }}
        </th>
        <th>
          {{ 'security.user_details.auth.table.column.last_used_at' | translate }}
        </th>
        <th>
          {{ 'security.user_details.auth.table.column.created_at' | translate }}
        </th>
        <th>
          {{ 'security.user_details.auth.table.column.actions' | translate }}
        </th>
      </tr>
    </thead>
    <tbody *ngIf="authKeyList?.length">
      <tr class="auth-key" *ngFor="let authKey of authKeyList">
        <td class="id">
          {{ authKey.authKeyId }}
        </td>
        <td>
          {{ formatLastUsedDateTime(authKey.lastUsedDateTime) }}
        </td>
        <td>
          {{ formatCreatedDateTime(authKey.createDateTime) }}
        </td>
        <td>
          <button
            class="ds-button --icon-delete --alert --plain auth-key-revoke"
            (click)="revokeAuthKeyConfirm(authKey)"
          >
            <span>{{ 'security.user_details.auth.revoke' | translate }}</span>
          </button>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="authKeyList?.length === 0">
      <tr>
        <td colspan="4" class="--no-data">
          {{ 'security.user_details.auth.no_data' | translate }}
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #revokeKeyModalTemplate let-data>
  <dl class="ds-details --vertical">
    <dt>{{ 'security.user_details.auth.modals.authkey.revoke.id' | translate }}</dt>
    <dd>
      <div class="ds-text --large --code">{{ data.authKeyIdToDelete }}</div>
    </dd>
  </dl>
</ng-template>
