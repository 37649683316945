import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Role } from '../../../../../app/shared/core/role';
import { User } from '../../../../../app/shared/core/user';
import { RolesService } from '../../../../../app/shared/security/modals/roles.service';
import { UserRolesService } from '../../../../../app/shared/security/user_roles.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButtonBar } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';

@Component({
  selector: 'app-attach-roles',
  templateUrl: './attach-roles.component.html',
})
export class AttachRolesComponent implements OnInit {
  // @ts-expect-error (legacy code incremental fix)
  @Input() user: User;
  @Output() closeModal = new EventEmitter<Role[] | null>();

  // @ts-expect-error (legacy code incremental fix)
  roles: Role[];
  selectedRoles: Role[] = [];
  isLoading = false;
  alertManager = new AlertsManager();
  footerButtonBar: UiButtonBar;

  constructor(private rolesService: RolesService, private userRolesService: UserRolesService) {
    this.footerButtonBar = UiButtonBar.configure((bar) => {
      bar.rightButtons = [
        UiButton.configure((button) => {
          button.titleId = 'security.user_details.permissions.modals.role_attach.cancel';
          button.onClick = this.cancel;
        }),
        UiButton.configure((button) => {
          button.titleId = 'security.user_details.permissions.modals.role_attach.submit';
          button.buttonStyle = 'primary';
          button.onClick = this.saveUserRoles;
          button.isDisabled_ƒ = () => this.isLoading || this.selectedRoles.length === 0;
        }),
      ];
    });
  }

  async ngOnInit() {
    await this.fetchRoles();
  }

  async fetchRoles() {
    this.isLoading = true;
    this.alertManager.clear();
    try {
      const response = await this.rolesService.list({ limit: 1000 });
      this.roles = response.data;
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e));
    } finally {
      this.isLoading = false;
    }
  }

  addOrRemoveSelectedRow(role: Role) {
    if (this.isAlreadyAttached(role.roleId)) {
      return;
    }

    const index = this.selectedRoles.findIndex((selectedRole) => selectedRole.roleId === role.roleId);
    if (index >= 0) {
      this.selectedRoles.splice(index, 1);
      return;
    }
    this.selectedRoles.push(role);
  }

  isRowChecked(roleId: string) {
    return this.selectedRoles.findIndex((role) => role.roleId === roleId) >= 0;
  }

  isAlreadyAttached(roleId: string): boolean {
    const attacheRoles = this.user ? this.user.roleList : [];
    return attacheRoles.find((r) => r.roleId === roleId) !== undefined;
  }

  cancel = () => {
    this.closeModal.emit(null);
  };

  saveUserRoles = async () => {
    if (!this.selectedRoles.length) {
      return;
    }

    this.isLoading = true;
    this.alertManager.clear();

    try {
      await Promise.all(
        this.selectedRoles.map(async (role) => {
          await this.userRolesService.attach(role.roleId);
        })
      );

      // Only after finishing all calls, execution will reach here
      this.closeModal.emit(this.selectedRoles);
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e));
    } finally {
      this.isLoading = false;
    }
  };
}
