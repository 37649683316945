<div class="ds-rows">
  <div class="ds-page-title">
    <h2>
      {{ 'security.mfa.mfa' | translate }}
    </h2>
  </div>
  <ng-container *ngIf="isMfaActive">
    <p class="ds-notice--info">{{ 'security.mfa.enabled' | translate }}</p>
    <ui-button [content]="inactivateMfaButton"></ui-button>
  </ng-container>

  <ng-container *ngIf="!isMfaActive">
    <p class="ds-notice--warning">{{ 'security.mfa.disabled' | translate }}</p>
    <ui-button [content]="activateMfaButton"></ui-button>
  </ng-container>
  <app-alerts [alertsManager]="alertManager"></app-alerts>
</div>

<ng-template #activateMfaModal let-data>
  <div dsDialogContent>
    <div class="ds-rows">
      <canvas id="otpQrCode" #otpQrCode style="margin: 0 auto"></canvas>
      <input class="ds-input" readonly value="{{ totpUri }}" />
      <p>
        {{ 'security.mfa.activationDscr' | translate }}
      </p>

      <div class="ds-field">
        <label class="ds-label">
          {{ 'security.mfa.code' | translate }}
        </label>
        <ui-input [content]="otpCode"></ui-input>
      </div>
      <app-alerts [alertsManager]="verifyOtpAlertManager"></app-alerts>
    </div>
  </div>
  <footer dsDialogFooter>
    <ui-button-bar [content]="activateMfaModalFooterButtonBar"></ui-button-bar>
  </footer>
</ng-template>

<ng-template #afterActivatedModal let-data>
  <div dsDialogContent>
    <h5>
      {{ 'security.mfa.backupCode' | translate }}
    </h5>
    <pre><div *ngFor="let backupCode of data">{{ backupCode }}</div></pre>
    <p [innerHTML]="'security.mfa.backupCodeDscr' | translate"></p>
  </div>
  <footer dsDialogFooter>
    <ui-button-bar [content]="afterActivatedModalCloseButton"></ui-button-bar>
  </footer>
</ng-template>
