import { Component, effect, inject, input } from '@angular/core';

import { getLoginUserData } from '@soracom/shared/data-access-auth';
import { Alert, AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { UserApiService } from '@soracom/shared-ng/soracom-api-ng-client';

import { injectActivateMfaDialog } from '../activate-mfa/activate-mfa.component';

@Component({
  selector: 'app-user-multi-factor-auth',
  templateUrl: './user-multi-factor-auth.component.html',
})
export class UserMultiFactorAuthComponent {
  userName = input<string>();
  alertManager = new AlertsManager();

  isMfaActive = false;
  isActivateMfaModalOpen = false;

  private userApi = inject(UserApiService);
  openActivateMfaDialog = injectActivateMfaDialog();

  constructor(private uiDsModalService: UiDsModalService) {
    effect(() => {
      if (this.userName()) {
        this.fetchMfaStatus();
      }
    });
  }

  showDisableMfaConfirmModal = async () => {
    await this.uiDsModalService.openGenericConfirmModal('security.mfa.modals.disable', {
      modalStyle: 'alert',
      headerIconClass: '--icon-delete',
      showLoadingProgressOnOkClick: true,
      onOkClick: this.inactivateMfa,
    });
  };

  get operatorId() {
    return getLoginUserData().operatorId;
  }

  async fetchMfaStatus() {
    try {
      const response = await this.userApi.getUserMFAStatus({ operatorId: this.operatorId, userName: this.userName()! });
      this.isMfaActive = this.checkIsMfaActive(response.data.status);
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e, 'security.unknown_error'));
    }
  }

  activateMfa = async () => {
    try {
      if (this.userName()) {
        this.alertManager.clear();
        const response = await this.userApi.enableUserMFA({ operatorId: this.operatorId, userName: this.userName()! });
        const totpUri = response.data.totpUri;
        const isVerified = await this.openActivateMfaDialog({ totpUri, username: this.userName()! });
        if (isVerified) {
          this.isMfaActive = true;
        }
      }
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e, 'security.unknown_error'));
    }
  };

  checkIsMfaActive(state?: string): boolean {
    return state === 'ACTIVE';
  }

  inactivateMfa = async () => {
    try {
      if (this.userName()) {
        this.alertManager.clear();
        await this.userApi.revokeUserMFA({ operatorId: this.operatorId, userName: this.userName()! });
        this.isMfaActive = false;
      }
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e, 'security.unknown_error'));
    }
  };
}
