import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { User } from '../../../../../app/shared/core/user';
import { RolesService } from '../../../../../app/shared/security/modals/roles.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';

@Component({
  selector: 'app-role-attached-users',
  templateUrl: './role-attached-users.component.html',
})
export class RoleAttachedUsersComponent implements OnInit {
  // @ts-expect-error (legacy code incremental fix)
  @Input() roleId: string;
  alertsManager = new AlertsManager();
  isLoading = false;
  // @ts-expect-error (legacy code incremental fix)
  users: User[];

  constructor(private rolesService: RolesService) {}

  ngOnInit() {}

  async ngOnChanges(changes: SimpleChanges) {
    if (changes?.roleId?.currentValue !== changes?.roleId?.previousValue) {
      await this.fetchRoleUsers();
    }
  }

  async fetchRoleUsers() {
    this.alertsManager.clear();
    this.isLoading = true;
    try {
      this.users = (await this.rolesService.users(this.roleId)).data;
    } catch (e) {
      this.alertsManager.add(Alert.fromApiError(e));
    } finally {
      this.isLoading = false;
    }
  }
}
