import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButtonBar } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { UiInput } from '../../../soracom-ui/ui-input/UiInput';
import { UserApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { getLoginUserData } from '@soracom/shared/data-access-auth';

const usernamePattern = /^[A-Za-z0-9_\-]{1,50}$/;

@Component({
  templateUrl: './add-user.component.html',
})
export class AddUserComponent extends DsModalContentBase<any, null | { isCancel: true }> implements OnInit {
  @Output() closeAddUserModal = new EventEmitter<boolean | void>();

  isAdding = false;
  isAdded = false;
  alertManager = new AlertsManager();
  footerButtonBar: UiButtonBar;
  username: UiInput;
  description: UiInput;
  formGroup: FormGroup;
  private userApi = inject(UserApiService);

  constructor(private fb: FormBuilder) {
    super();
    this.formGroup = this.fb.group({
      username: new FormControl('', [Validators.required, Validators.pattern(usernamePattern)]),
      description: new FormControl(''),
    });

    this.footerButtonBar = UiButtonBar.configure((bar) => {
      bar.rightButtons = [
        UiButton.configure((button) => {
          button.titleId = 'security.users.modals.create.cancel';
          button.onClick = () => {
            this.close({ isCancel: true });
          };
          button.isDisabled_ƒ = this.cancelDisabled;
        }),

        UiButton.configure((button) => {
          button.titleId = 'security.users.modals.create.do_create';
          button.onClick = this.saveUser;
          button.buttonStyle = 'primary';
          button.isDisabled_ƒ = () => this.isAdding || this.isAdded || !this.formGroup.valid;
        }),
      ];
    });

    this.username = UiInput.configure((input) => {
      input.labelId = 'security.users.modals.create.name';
      input.required = true;
      input.pattern = usernamePattern;
      // @ts-expect-error (legacy code incremental fix)
      input.formControl = this.formGroup.get('username');
      input.validationErrorMessageStringId = 'security.users.modals.create.validation.username';
    });

    this.description = UiInput.configure((input) => {
      input.labelId = 'security.users.modals.create.description';
      // @ts-expect-error (legacy code incremental fix)
      input.formControl = this.formGroup.get('description');
      input.validationErrorMessageStringId = 'security.users.modals.create.validation.description';
    });
  }

  ngOnInit(): void {
    this.modalRef.modalConfig.title = 'security.users.modals.create.header';
    this.modalRef.disableEscKeyClose = this.cancelDisabled;
  }

  saveUser = async () => {
    this.isAdding = true;
    this.alertManager.clear();
    try {
      const formValue = this.formGroup.value;
      await this.userApi.createUser({
        operatorId: getLoginUserData().operatorId,
        userName: formValue.username,
        createUserRequest: {
          description: formValue.description,
        },
      });
      this.alertManager.add(Alert.success('security.users.modals.create.success'));
      this.isAdded = true;

      this.modalRef.close();
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e));
    } finally {
      this.isAdding = false;
    }
  };

  private cancelDisabled = () => this.isAdding || this.isAdded;
}
