import { LegacyAny } from '@soracom/shared/core';

import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RemoteLogger } from '@soracom/shared/remote-logging';
import { formatDateTime } from '@soracom/shared/util-common';
import { snakeCase } from 'lodash-es';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { CredentialDetailsComponent } from './credential-details/credential-details.component';
import { DeleteCredentialsComponent } from './delete-credentials/delete-credentials.component';
import { RegisterCredentialsComponent } from '@user-console/credentials';
import { CredentialApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { CredentialsModel } from '@soracom/shared/soracom-api-typescript-client';

@Component({
  selector: 'app-credentials',
  templateUrl: './credentials.component.html',
})
export class CredentialsComponent implements OnInit, OnDestroy {
  registerCredentialSetButton = new UiButton();
  alertManager = new AlertsManager();
  isLoading = false;
  credentialsSets: CredentialsModel[] = [];

  private credentialApi = inject(CredentialApiService);

  // @ts-expect-error (legacy code incremental fix)
  @ViewChild('credentialDeleteModal', { static: true, read: TemplateRef }) credentialDeleteModalRef: TemplateRef<any>;

  constructor(
    private translate: TranslateService,
    private uiDsModalService: UiDsModalService,
  ) {
    RemoteLogger.disableRemoteLogging();
    this.registerCredentialSetButton = UiButton.configure((button) => {
      button.buttonStyle = 'primary';
      button.classes = ['x-add-credentials-set', 'credential-add-button'];
      button.titleId = 'security.credentials.register';
      button.onClick = this.openRegisterCredentialSetModal;
    });
  }

  async ngOnInit() {
    await this.fetchCredentialSet();
  }

  ngOnDestroy(): void {
    RemoteLogger.enableRemoteLogging();
  }

  async fetchCredentialSet() {
    this.isLoading = true;
    this.alertManager.clear();
    try {
      this.credentialsSets = (await this.credentialApi.listCredentials({})).data;
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e));
    } finally {
      this.isLoading = false;
    }
  }

  showCredentialsSet(credentials: CredentialsModel) {
    this.uiDsModalService.open(CredentialDetailsComponent, {
      title: 'security.credentials.modals.show.header',
      data: {
        ...credentials,
      },
    });
  }

  openRegisterCredentialSetModal = () => {
    this.uiDsModalService
      .openAndWaitForResult(RegisterCredentialsComponent, {
        title: 'security.credentials.modals.register.header',
      })
      .then((addedData) => {
        if (addedData) {
          this.credentialsSets.push(addedData);
        }
      });
  };

  formatCredentialsType(type: string) {
    return this.translate.instant(`security.credentials.modals.register.${snakeCase(type)}`);
  }

  formatCreateDateTime(unixSec: number) {
    return formatDateTime(unixSec * 1000, 'datetime', { fallback: '' });
  }

  formatLastUsedDateTime(unixSec: number) {
    if (unixSec === undefined || unixSec === null) {
      return this.translate.instant('security.credentials.not_used');
    }
    return formatDateTime(unixSec * 1000, 'datetime');
  }

  async deleteCredentialsSet($event: LegacyAny, credentials: CredentialsModel) {
    $event.stopPropagation();
    const deletedData = await this.uiDsModalService.openAndWaitForResult(DeleteCredentialsComponent, {
      title: 'security.credentials.modals.delete.header',
      isLoading: true,
      modalStyle: 'delete',
      subtitle: 'security.credentials.modals.delete.message',
      data: {
        ...credentials,
      },
    });
    if (deletedData) {
      const index = this.credentialsSets.findIndex((set) => set.credentialsId === deletedData.credentialsId);
      this.credentialsSets.splice(index, 1);
    }
  }
}
