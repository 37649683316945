import { Component } from '@angular/core';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';

export interface DeactivateMFAModalComponentData {
  password: string;
}

/**
 * Deactivate-mfa modal.
 */
@Component({
  selector: 'deactivate-mfa-modal',
  templateUrl: './deactivate-mfa-modal.component.html',
  styles: [],
})
export class DeactivateMfaModalComponent extends DsModalContentBase<null, DeactivateMFAModalComponentData> {
  inputPassword: string = '';

  submit() {
    this.close({ password: this.inputPassword });
  }
}
