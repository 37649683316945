<div class="ds-rows">
  <div class="ds-text--regular">
    {{ i[ 'Adding a trust policy allows another authorized user to operate as this SAM user.' ]}}
  </div>
  <div [ngClass]="{'--loading': isLoading}">
    <json-editor [options]="editorOptions" [data]="editorData" #editor> </json-editor>
  </div>
  <span [innerHTML]="i.syntaxHelp"></span>
  <app-alerts [alertsManager]="alertsManager"></app-alerts>
  <ui-button-bar [content]="buttonBar"></ui-button-bar>
</div>

<ng-template #savePermissionConfirmModalTemplate>
  <div dsDialogcontent>
    <div [innerHTML]=" i.saveConfirmationText | interpolate: {currentSamUser} "></div>
  </div>
</ng-template>

<ng-template #deletePermissionConfirmModalTemplate> </ng-template>

<ng-template #getExampleModalTemplate>
  <div dsDialogcontent class="ds-rows">
    <form [formGroup]="exampleValueForm">
      <div class="ds-field">
        <label class="ds-text --label">{{ i['Trusted user type']}}</label>
        <label class="ds-radio">
          <input type="radio" [value]="'operator'" formControlName="switchee"autocomplete="off">
          <div>{{ label.Operator }}</div>
        </label>
        <label class="ds-radio">
          <input type="radio" [value]="'user'" formControlName="switchee" autocomplete="off">
          <div>{{ label['SAM user'] }}</div>
        </label>
      </div>

      <div class="ds-field">
        <label class="ds-text --label">{{ i['Trusted Operator ID'] }}</label>
        <div class="ds-input">
          <input
            id="trustedOperatorId"
            type="text"
            formControlName="sourceOperator"
            autocomplete="off"
            placeholder="OP0000000000"
            minlength="12"
            maxlength="12"
            pattern="OP[0-9]{10}"
          >
        </div>
      </div>

      <div class="ds-field" *ngIf="exampleValueForm.get('switchee')?.value === 'user'">
        <label class="ds-text --label">{{ i['Trusted user name'] }}</label>
        <div class="ds-input">
          <input
            id="trustedUserName"
            type="text"
            formControlName="user"
            [placeholder]="i['Trusted user name']"
            autocomplete="off"
            maxlength="50"
            minlength="1"
            pattern="[A-Za-z0-9_\-]{1,50}"
          >
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #copySrnModalTemplate>
  <p>{{ i['This trust policy cannot be updated automatically. Please copy the SRN below and add it to the policy manually.']}}</p>

  <dl class="ds-details">
    <dt>SRN</dt>
    <dd>
      <div>
        <code style="font-size: larger;">{{srnToCopy}}</code>
      </div>
       <button [appCopyButton]="srnToCopy">
          <span>{{ i['Copy SRN'] }}</span>
        </button>
    </dd>
  </dl>
</ng-template>
