import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { User } from '../../../../../../app/shared/core/user';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButtonBar } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiInput } from '../../../../soracom-ui/ui-input/UiInput';
import { getLoginUserData } from '@soracom/shared/data-access-auth';
import { UserApiService } from '@soracom/shared-ng/soracom-api-ng-client';

@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
})
export class EditPasswordComponent implements OnInit {
  @Input() operation: 'create' | 'update' = 'create';
  // @ts-expect-error (legacy code incremental fix)
  @Input() user: User;
  @Output() closeModal = new EventEmitter<boolean>();

  footerButtonBar: UiButtonBar;
  alertManager = new AlertsManager();
  isLoading = false;

  // @ts-expect-error (legacy code incremental fix)
  currentPassword: UiInput;
  // @ts-expect-error (legacy code incremental fix)
  password: string;

  private userApi = inject(UserApiService);

  constructor() {
    this.footerButtonBar = UiButtonBar.configure((bar) => {
      bar.rightButtons = [
        UiButton.configure((button) => {
          button.titleId = this.getI18NKey('cancel');
          button.onClick = () => this.close();
        }),
        UiButton.configure((button) => {
          button.titleId = this.getI18NKey('submit');
          button.onClick = this.updatePassword;
          button.buttonStyle = 'primary';
          button.isDisabled_ƒ = () => !this.password || (this.operation === 'update' && !this.currentPassword.value);
        }),
      ];
    });
  }

  ngOnInit(): void {
    if (this.operation === 'update') {
      this.currentPassword = UiInput.configure((input) => {
        input.label = LegacyTextContent.translation(
          'security.user_details.auth.modals.password_update.current_password'
        );
        input.type = 'password';
        input.required = true;
      });
    }
  }

  getI18NKey(key: string) {
    return `security.user_details.auth.modals.${
      this.operation === 'create' ? 'password_add' : 'password_update'
    }.${key}`;
  }

  onPasswordChange(password: string) {
    this.password = password;
  }

  close = (wasSaved = false) => {
    this.closeModal.emit(wasSaved);
  };

  updatePassword = async () => {
    try {
      this.alertManager.clear();
      this.isLoading = true;
      this.operation === 'create'
        ? await this.userApi.createUserPassword({
            operatorId: getLoginUserData().operatorId,
            userName: this.user.userName,
            createUserPasswordRequest: { password: this.password },
          })
        : await this.userApi.updateUserPassword({
            operatorId: getLoginUserData().operatorId,
            userName: this.user.userName,
            updatePasswordRequest: {
              newPassword: this.password,
              currentPassword: this.currentPassword.value,
            },
          });
      this.close(true);
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e, 'security.unknown_error'));
    } finally {
      this.isLoading = false;
    }
  };
}
