<section dsDialogContent>
  <div class="ds-notice --alert">
    {{ 'security.auth_keys.modals.create.message_important' | translate }}
  </div>
  <div class="ds-notice --success" *ngIf="authKeyData">
    {{ 'security.auth_keys.modals.create.message' | translate }}
  </div>
  <dl class="ds-details --vertical" *ngIf="authKeyData">
    <dt>{{ 'security.auth_keys.modals.create.id' | translate }}</dt>
    <dd>
      <div id="auth-key-id" class="ds-text --large --code">{{ authKeyData.authKeyId }}</div>
    </dd>
    <dt>{{ 'security.auth_keys.modals.create.secret' | translate }}</dt>
    <dd>
      <div id="auth-key-secret" class="ds-text --large --code">{{ authKeyData.authKey }}</div>
    </dd>
  </dl>
  <app-alerts [alertsManager]="alertManager"></app-alerts>
</section>
<footer dsDialogFooter>
  <button class="ds-button --primary cc-auth" [attr.data-clipboard-text]="textToCopy()" [disabled]="!canCopy()">
    <span>{{ 'security.auth_keys.modals.create.copy' | translate }}</span>
  </button>
  <button class="ds-button authkey-add-close" (click)="close()">
    <span>{{ 'security.auth_keys.modals.create.close' | translate }}</span>
  </button>
</footer>
