import { LegacyAny } from '@soracom/shared/core';

import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { RoleApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { LoginUserDataService } from '@soracom/shared/data-access-auth';
import { ListRolesResponse } from '@soracom/shared/soracom-api-typescript-client';
import { formatDateTime } from '@soracom/shared/util-common';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { CreateRoleComponent } from './create-role/create-role.component';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
})
export class RolesComponent implements OnInit {
  // @ts-expect-error (legacy code incremental fix)
  @Input() moveToPage: EventEmitter<string>;

  alertManager = new AlertsManager();
  createRoleButton = new UiButton();

  isLoading = false;
  // @ts-expect-error (legacy code incremental fix)
  roles: ListRolesResponse[];

  constructor(
    private translate: TranslateService,
    private uiDsModalService: UiDsModalService,
    private roleApiService: RoleApiService,
    private loginUserDataService: LoginUserDataService
  ) {
    this.createRoleButton = UiButton.configure((button) => {
      button.titleId = 'security.roles.create';
      button.buttonStyle = 'primary';
      button.classes = ['role-add-button'];
      button.onClick = this.openCreateRoleModal;
    });
  }

  async ngOnInit() {
    await this.fetchRoles();
  }

  async fetchRoles() {
    try {
      this.isLoading = true;
      this.alertManager.clear();
      const operatorId = this.loginUserDataService.getLoginUser()?.operatorId;
      // @ts-expect-error (legacy code incremental fix)
      this.roles = (await this.roleApiService.listRoles({ operatorId })).data;
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e));
    } finally {
      this.isLoading = false;
    }
  }

  openCreateRoleModal = () => {
    this.uiDsModalService
      .openAndWaitForResult(CreateRoleComponent, {
        title: 'security.roles.modals.create.header',
      })
      .then((result: LegacyAny) => {
        if (result?.isAdded) {
          this.fetchRoles();
        }
      });
  };

  formatUpdateDateTime(unixSec: number) {
    if (unixSec === undefined || unixSec === null) {
      return this.translate.instant('security.roles.not_used');
    }
    return formatDateTime(unixSec * 1000, 'datetime');
  }

  formatCreateDateTime(unixSec: number) {
    return formatDateTime(unixSec * 1000, 'datetime');
  }
}
