<div class="ds-modal">
  <div class="ds-dialog" [ngClass]="{ 'ds-dialog--loading-refresh': isLoading }">
    <div class="ds-dialog__header">
      <div class="ds-dialog__title" [innerHTML]="getI18NKey('header') | translate"></div>
    </div>

    <div class="ds-dialog__main">
      <ui-input *ngIf="operation === 'update'" [content]="currentPassword"></ui-input>
      <ui-password-confirm (passwordChange)="onPasswordChange($event)"></ui-password-confirm>
      <app-alerts [alertsManager]="alertManager"></app-alerts>
    </div>

    <div class="ds-dialog__footer">
      <ui-button-bar [content]="footerButtonBar"></ui-button-bar>
    </div>
  </div>
</div>
