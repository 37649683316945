<div class="ds-modal">
  <div class="ds-dialog" [ngClass]="{ 'ds-dialog--loading-refresh': isLoading }">
    <div class="ds-dialog__header">
      <div
        class="ds-dialog__title"
        [innerHTML]="'security.user_details.permissions.modals.role_attach.header' | translate"
      ></div>
    </div>

    <app-alerts [alertsManager]="alertManager"></app-alerts>
    <div class="ds-dialog__main">
      <form>
        <table class="ds-datatable--embedded">
          <thead>
            <tr>
              <th class="ds-datatable__col--min"></th>
              <th>{{ 'security.user_details.permissions.modals.role_attach.table.column.id' | translate }}</th>
              <th>{{ 'security.user_details.permissions.modals.role_attach.table.column.description' | translate }}</th>
            </tr>
          </thead>
          <tbody *ngIf="roles?.length">
            <tr *ngFor="let role of roles" (click)="addOrRemoveSelectedRow(role)" class="--clickable">
              <td>
                <label class="ds-checkbox">
                  <input
                    type="checkbox"
                    [checked]="isRowChecked(role.roleId)"
                    [disabled]="isAlreadyAttached(role.roleId)"
                  />
                </label>
              </td>
              <td>
                {{ role.roleId }}
              </td>
              <td>
                {{ role.description }}
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!isLoading && !roles?.length">
            <th>{{ 'security.user_details.permissions.modals.role_attach.no_data' | translate }}</th>
          </tbody>
        </table>
      </form>
    </div>

    <div class="ds-dialog__footer">
      <ui-button-bar [content]="footerButtonBar"></ui-button-bar>
    </div>
  </div>
</div>
