import { LegacyAny } from '@soracom/shared/core';

import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getLoginUserData } from '@soracom/shared/data-access-auth';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiInput } from '../../soracom-ui/ui-input/UiInput';
import { UiPasswordConfirmComponent } from '../../soracom-ui/ui-password-confirm/ui-password-confirm.component';
import { OperatorApiService, UserApiService } from '@soracom/shared-ng/soracom-api-ng-client';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
})
export class UpdatePasswordComponent implements OnInit {
  alertManager = new AlertsManager();
  currentPassword = new UiInput();
  updatePasswordButton = new UiButton();

  // @ts-expect-error (legacy code incremental fix)
  @ViewChild(UiPasswordConfirmComponent, { static: true }) uiPasswordConfirmRef: UiPasswordConfirmComponent;

  // @ts-expect-error (legacy code incremental fix)
  newPassword: string;
  isUpdating = false;

  private userApi = inject(UserApiService);
  private operatorApi = inject(OperatorApiService);

  constructor(private translate: TranslateService) {
    this.currentPassword = UiInput.configure((input) => {
      input.labelId = 'security.password.curr_password';
      input.placeholder_ƒ = () => this.translate.instant('security.password.curr_password');
      input.autocomplete = 'current-password';
      input.type = 'password';
      input.name = 'currPassword';
      input.required = true;
      input.enableViewPasswordToggle = true;
      input.id = 'curr-password';
    });

    this.updatePasswordButton = UiButton.configure((button) => {
      button.titleId = 'security.password.change_password';
      button.buttonStyle = 'primary';
      button.isDisabled_ƒ = () => this.isUpdating || !this.currentPassword.value || !this.newPassword;
      button.onClick = this.updatePassword;
      button.classes = ['change-password'];
    });
  }

  ngOnInit(): void {}

  onPasswordChange = ($event: LegacyAny) => {
    this.newPassword = $event;
  };

  updatePassword = async () => {
    try {
      this.alertManager.clear();
      const currPassword = this.currentPassword.value;
      const newPassword = this.newPassword;

      if (getLoginUserData().isRootUser) {
        await this.operatorApi.updateOperatorPassword({
          operatorId: getLoginUserData().operatorId,
          updatePasswordRequest: {
            currentPassword: currPassword,
            newPassword,
          },
        });
      } else {
        const username = getLoginUserData().emailOrUserName;
        await this.userApi.updateUserPassword({
          operatorId: getLoginUserData().operatorId,
          userName: username,
          updatePasswordRequest: {
            newPassword: newPassword,
            currentPassword: currPassword,
          },
        });
      }

      this.alertManager.add(Alert.success('security.password.update_password_success'));
      this.currentPassword.value = '';
      this.uiPasswordConfirmRef.reset();
    } catch (e: LegacyAny) {
      if (e?.data?.code === 'COM0017') {
        this.alertManager.add(Alert.danger('security.password.sam_permission_guide'));
        return;
      }
      this.alertManager.add(Alert.fromApiError(e, 'security.unknown_error'));
    } finally {
      this.isUpdating = false;
    }
  };
}
