import { Component, inject, input, output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { getLoginUserData } from '@soracom/shared/data-access-auth';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UserApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { DsModalContentBase, UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';

export interface ActivateMfaDialogInput {
  totpUri: string;
  username: string;
}

export interface ActivateMfaDialogOutput {
  isVerified: boolean;
}
@Component({
  templateUrl: './activate-mfa.component.html',
})
export class ActivateMfaComponent extends DsModalContentBase<ActivateMfaDialogInput, boolean> {
  alertManager = new AlertsManager();
  otpCodeFormControl = new FormControl('', [Validators.required, Validators.pattern(/^\d{6}$/)]);

  isLoading = false;
  isVerified = false;

  get totpUri(): string {
    return this.getInput().totpUri;
  }

  get username(): string {
    return this.getInput().username;
  }

  private userApi = inject(UserApiService);

  canCancel() {
    return !this.isLoading && !this.isVerified;
  }

  canVerify() {
    return this.otpCodeFormControl.valid && !this.isLoading && !this.isVerified;
  }

  cancel() {
    this.close(false);
  }

  async verify() {
    try {
      this.alertManager.clear();
      this.isLoading = true;
      await this.userApi.verifyUserMFA({
        operatorId: getLoginUserData().operatorId,
        userName: this.username,
        mFAAuthenticationRequest: { mfaOTPCode: this.otpCodeFormControl.value || undefined },
      });
      this.alertManager.add(Alert.success('security.mfa.activated'));
      this.isVerified = true;
      setTimeout(() => {
        this.close(true);
      }, 1500);
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e, 'security.unknown_error'));
    } finally {
      this.isLoading = false;
    }
  }
}

export function injectActivateMfaDialog() {
  const modalService = inject(UiDsModalService);
  return async function openActivateMfaDialog(data: ActivateMfaDialogInput) {
    return modalService.openAndWaitForResult(ActivateMfaComponent, {
      title: 'security.mfa.activate',
      headerIconClass: '--icon-verified',
      data,
    });
  };
}
