<div class="ds-rows">
  <h2>{{ 'security.credentials.title' | translate }}</h2>
  <div class="ds-notice--info">{{ 'security.credentials.description' | translate }}</div>
  <app-alerts [alertsManager]="alertManager"></app-alerts>
  <div>
    <ui-button [content]="registerCredentialSetButton"></ui-button>
  </div>
  <div class="--responsive">
    <table
      class="ds-datatable --embedded --compact table-credential-list"
      [ngClass]="{ 'ds-datatable--loading-refresh': isLoading }"
    >
      <thead>
        <tr>
          <th class="id">{{ 'security.credentials.table.column.id' | translate }}</th>
          <th class="type">{{ 'security.credentials.table.column.type' | translate }}</th>
          <th class="created">{{ 'security.credentials.table.column.created' | translate }}</th>
          <th class="last-used">{{ 'security.credentials.table.column.last_used' | translate }}</th>
          <th class="description">{{ 'security.credentials.table.column.description' | translate }}</th>
          <th class="actions"></th>
        </tr>
      </thead>
      <tbody *ngIf="!isLoading && credentialsSets.length > 0">
        <tr
          class="role x-credentials-set --clickable"
          *ngFor="let credentials of credentialsSets"
          (click)="showCredentialsSet(credentials)"
        >
          <td class="id">{{ credentials.credentialsId }}</td>
          <td class="type">{{ formatCredentialsType(credentials.type) }}</td>
          <td class="created">{{ formatCreateDateTime(credentials.createDateTime) }}</td>
          <td class="last-used">{{ formatLastUsedDateTime(credentials.lastUsedDateTime) }}</td>
          <td class="description">{{ credentials.description }}</td>
          <td class="actions">
            <button
              class="ds-button --alert --icon-delete --plain"
              type="button"
              (click)="deleteCredentialsSet($event, credentials)"
            >
              <span>{{ 'security.credentials.delete' | translate }}</span>
            </button>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!isLoading && credentialsSets.length === 0">
        <tr>
          <td colspan="999">{{ 'security.credentials.no_data' | translate }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
