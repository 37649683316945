import { Component, inject, OnInit } from '@angular/core';
import { CoverageType } from '@foundation/coverage-type';
import { CredentialApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { Alert, AlertsManager, UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { LegacyAny } from '@soracom/shared/core';
import { getOperatorData } from '@soracom/shared/data-access-auth';
import { CredentialsModel } from '@soracom/shared/soracom-api-typescript-client';
import { groupsService } from '@soracom/shared/soracom-services-ui/groups-ui';
import { Group } from '@soracom/shared/group';
import { EventHandler } from '../../../../../app/shared/event_handlers/event_handler';
import { EventHandlersService } from '../../../event-handler/service/event_handlers.service';

interface groupError {
  groupId: string;
  groupName: string;
  coverageType: CoverageType;
  services: string[];
}

interface eventHandlerError {
  groupId: string;
  groupName: string;
  description: string;
  coverageType: CoverageType;
  name: string;
  id: string;
}

@Component({
  templateUrl: './delete-credentials.component.html',
  providers: [],
})
export class DeleteCredentialsComponent
  extends DsModalContentBase<CredentialsModel, CredentialsModel>
  implements OnInit
{
  closeButton = new UiButton();
  okButton = new UiButton();
  alertManager = new AlertsManager();
  usedOnGroupError: groupError[] = [];
  usedOnEventHandlerError: eventHandlerError[] = [];
  isDeleting = false;
  data = this.getInput();
  private credentialService = inject(CredentialApiService);

  constructor(private eventHandlerService: EventHandlersService) {
    super();
    // initialize components
    this.closeButton.titleId = 'security.credentials.modals.delete.cancel';
    this.closeButton.onClick = () => this.close();

    this.okButton.titleId = 'security.credentials.modals.delete.submit';
    this.okButton.buttonStyle = 'danger';
    this.okButton.isDisabled_ƒ = () => this.isError() || this.isDeleting;
    // @ts-expect-error (legacy code incremental fix)
    this.okButton.buttonLoadingState_ƒ = () => (this.isDeleting ? 'refresh' : null);

    // register onclick event
    this.okButton.onClick = async () => {
      try {
        this.isDeleting = true;
        const credentials = this.getInput();
        await this.deleteCredentials(credentials);
        this.modalRef.close(
          credentials // back to parent component
        );
      } catch (e) {
        // console.debug(e);
      } finally {
        this.isDeleting = false;
      }
    };
  }

  async ngOnInit() {
    this.modalRef.modalConfig.isLoading = true;
    try {
      await this.validateCredentialsSet();
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e));
    }
    this.modalRef.modalConfig.isLoading = false;
  }

  async validateCredentialsSet() {
    const coverageTypes = getOperatorData().getCoverageTypes();
    let groupError: LegacyAny = [];
    let eventHandlerError: LegacyAny = [];
    await Promise.all(
      coverageTypes.map(async (coverageType) => {
        const groupMap = await groupsService.fetchAll(coverageType);
        groupError = groupError.concat(
          this.checkCredentialsSetIsUsedOnGroupConfig(this.data.credentialsId, coverageType, groupMap)
        );

        // @ts-expect-error (legacy code incremental fix)
        const eventHandlers = await this.eventHandlerService.fetch(null, coverageType);
        eventHandlerError = eventHandlerError.concat(
          this.checkCredentialsSetIsUsedOnEventHandler(this.data.credentialsId, coverageType, eventHandlers)
        );
      })
    );
    this.usedOnGroupError = groupError;
    this.usedOnEventHandlerError = eventHandlerError;
  }

  deleteCredentials = async (credentialsToDelete: CredentialsModel) => {
    try {
      this.alertManager.clear();
      await this.validateCredentialsSet();
      if (this.isError()) {
        return Promise.reject(new Error('Validation Error')); // validation result will be shown on User Console.
      }
      await this.credentialService.deleteCredential({ credentialsId: credentialsToDelete.credentialsId });
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e));
      return Promise.reject(new Error('API Error'));
    }
  };

  isCredentialsSetUsedOnConfig = (credentialsId: LegacyAny, config: LegacyAny) => {
    const queue: LegacyAny = [];
    queue.push(config);
    while (queue.length > 0) {
      const target = queue.shift();
      for (const k in target) {
        if (!target.hasOwnProperty(k)) {
          continue;
        }

        if (typeof target[k] === 'string' && k.includes('credentialsId') && target[k] === credentialsId) {
          return true;
        }

        if (typeof target[k] === 'object') {
          queue.push(target[k]);
        }
      }
    }
    return false;
  };

  checkCredentialsSetIsUsedOnGroupConfig = (
    credentialsId: string,
    coverageType: CoverageType,
    groupMap: Map<string, Group>
  ): groupError[] => {
    const usedOnGroupError: LegacyAny[] = [];
    groupMap.forEach((group) => {
      const groupErr: groupError = {
        groupId: group.id,
        groupName: group.name,
        services: [],
        coverageType,
      };

      for (let serviceName of Object.keys(group.configuration)) {
        if (this.isCredentialsSetUsedOnConfig(credentialsId, (group.configuration as LegacyAny)[serviceName])) {
          serviceName = serviceName.replace(/(.)([A-Z].*)/, '$1 $2'); // add space before Upper case at the middle e.g SoracomFunk -> Soracom Funk
          groupErr.services.push(serviceName);
        }
      }

      if (groupErr.services.length) {
        usedOnGroupError.push(groupErr);
      }
    });
    return usedOnGroupError;
  };

  checkCredentialsSetIsUsedOnEventHandler = (
    credentialsId: string,
    coverageType: CoverageType,
    eventHandlers: EventHandler[]
  ) => {
    const usedOnEventHandlerError: LegacyAny[] = [];
    for (const key in eventHandlers) {
      if (!eventHandlers.hasOwnProperty(key)) {
        continue;
      }
      const eventHandler: EventHandler = eventHandlers[key];
      const eventHandlerErr = {
        id: eventHandler.handlerId,
        name: eventHandler.name,
        description: eventHandler.description,
        coverageType,
      };

      for (const config of eventHandler.actionConfigList) {
        if (this.isCredentialsSetUsedOnConfig(credentialsId, config)) {
          usedOnEventHandlerError.push(eventHandlerErr);
        }
      }
    }
    return usedOnEventHandlerError;
  };

  isError() {
    return this.usedOnEventHandlerError.length > 0 || this.usedOnGroupError.length > 0;
  }

  getCoverageNameRef(coverageType: CoverageType): string {
    return coverageType === 'jp'
      ? 'security.credentials.modals.delete.coverage_type.jp'
      : 'security.credentials.modals.delete.coverage_type.g';
  }
}
