import { LegacyAny } from '@soracom/shared/core';

import { Component, Input, SimpleChanges, TemplateRef, ViewChild, inject } from '@angular/core';
import { UiButtonBar } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { LoginUserDataService } from '@soracom/shared/data-access-auth';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { User } from '../../../../app/shared/core/user';
import { UserApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { getCompactJsonText } from '../roles/role-permissions/role-permissions.component';
import { JsonEditorComponent, JsonEditorOptions } from '@soracom/forks/ang-jsoneditor';
import { Observable, debounceTime } from 'rxjs';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { localizeAll } from '@soracom/shared-ng/i18n';
import { i18n } from './TrustPolicyConfigComponentI18n';
import { Labels } from '@soracom/shared/i18n';
import { OperatorSRN, SRN, TrustPolicy, UserSRN, validateSamUserName } from '@soracom/shared/soracom-platform';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export const schema = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  properties: {
    statements: {
      type: 'array',
      minItems: 1,
      items: {
        type: 'object',
        properties: {
          effect: {
            type: 'string',
            enum: ['allow', 'deny'],
          },
          principal: {
            type: 'object',
            properties: {
              soracom: {
                type: 'array',
                minItem: 1,
                items: {
                  type: 'string',
                },
              },
            },
          },
          condition: {
            type: 'string',
          },
        },
        required: ['effect', 'principal'],
      },
    },
  },
  required: ['statements'],
};

@Component({
  selector: 'app-trust-policy-config',
  templateUrl: './trust-policy-config.component.html',
})
export class TrustPolicyConfigComponent {
  // @mason 2023-07-02: This i18n is a little weird because I am playing with some experimental compact i18n defs.
  i = localizeAll(i18n);
  label = localizeAll(Labels);

  @ViewChild('deletePermissionConfirmModalTemplate', { static: true, read: TemplateRef })
  // @ts-expect-error (legacy code incremental fix)
  deletePermissionConfirmModalTemplate: TemplateRef<any>;

  @ViewChild('savePermissionConfirmModalTemplate', { static: true, read: TemplateRef })
  // @ts-expect-error (legacy code incremental fix)
  savePermissionConfirmModalTemplate: TemplateRef<any>;

  @ViewChild('getExampleModalTemplate', { static: true, read: TemplateRef })
  // @ts-expect-error (legacy code incremental fix)
  getExampleModalTemplate: TemplateRef<any>;

  @ViewChild('copySrnModalTemplate', { static: true, read: TemplateRef })
  // @ts-expect-error (legacy code incremental fix)
  copySrnModalTemplate: TemplateRef<any>;

  // @ts-expect-error (legacy code incremental fix)
  @ViewChild('editor', { static: false }) editor: JsonEditorComponent;

  userApiService = inject(UserApiService);

  // @ts-expect-error (legacy code incremental fix)
  @Input() user: User;

  get currentSamUser() {
    return this.user.userName ?? 'WTF';
  }

  // @ts-expect-error (legacy code incremental fix)
  editorData: object;
  editorOptions = new JsonEditorOptions();
  buttonBar = new UiButtonBar();
  alertsManager = new AlertsManager();
  isLoading = false;
  permissionText = {
    original: '',
    edited: '',
  };
  operatorId: string;
  // @ts-expect-error (legacy code incremental fix)
  userName: string;
  isJsonDataValid = false;
  exampleValueForm = new FormGroup({
    switchee: new FormControl('operator', [Validators.required]), // 'Operator' or 'User'
    sourceOperator: new FormControl('', [Validators.required]),
    user: new FormControl('', (f: AbstractControl) => {
      // @mason 2022-07-05: There are other ways to do this but it is the night before Discovery 2023 so I am trying to make the least amount of changes to existing code.

      if (!this.exampleValueForm) {
        // This will execute early during form setup, so don't do anything here is this.exampleValueForm is not set yet.
        return null;
      } else {
        const value = this.exampleValueForm.value;
        const destType = value.switchee === 'operator' ? 'Operator' : 'User';
        // This field should only be required if the destination type is User.
        if (destType === 'User') {
          // This error won't show up anywhere but the object value is required for this code to build.
          const result = validateSamUserName(f.value) ? null : { message: 'Invalid SAM username' };
          return result;
        }
        return null;
      }
    }),
  });

  constructor(private loginUserDataService: LoginUserDataService, private uiDsModalService: UiDsModalService) {
    // @ts-expect-error (legacy code incremental fix)
    this.operatorId = this.loginUserDataService.getLoginUser()?.operatorId;

    this.exampleValueForm.get('sourceOperator'); // @mason 2023-07-02: I think this doesn't make sense to default to the current OperatorID maybe? .setValue(this.operatorId);

    this.buttonBar = UiButtonBar.configure((bar) => {
      bar.leftButtons = [
        UiButton.configure((addTrustedUserButton) => {
          addTrustedUserButton.titleId = this.i['Add trusted user'];
          addTrustedUserButton.onClick = this.openAddTrustedUserModal;
          addTrustedUserButton.isDisabled_ƒ = () => this.isLoading;
        }),
        UiButton.configure((savePermissionsButton) => {
          savePermissionsButton.localizedTitle = this.i['Save trust policy'];
          savePermissionsButton.buttonStyle = 'primary';
          savePermissionsButton.onClick = this.saveInlinePermissions;
          savePermissionsButton.isDisabled_ƒ = this.isSavePermissionsButtonDisabled;
        }),
      ];
      bar.rightButtons = [
        UiButton.configure((resetButton) => {
          resetButton.localizedTitle = this.i['Delete trust policy'];
          resetButton.iconName = 'icon-delete';
          resetButton.classes = ['--alert'];
          resetButton.onClick = this.deletePermissionsConfirm;
          resetButton.isDisabled_ƒ = () => !this.permissionText.edited || this.permissionText.edited === '{}';
        }),
      ];
    });

    this.editorOptions.mode = 'text';
    this.editorOptions.schema = schema;
    this.editorOptions.enableTransform = false;
    this.editorOptions.enableSort = false;
    this.editorOptions.onValidationError = this.onJsonEditorValidationError;
    const editorOnChange = new Observable((observer) => {
      this.editorOptions.onChange = () => {
        observer.next();
      };
    });

    editorOnChange.pipe(debounceTime(300), takeUntilDestroyed()).subscribe(this.onJsonDataChangeEvent);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.user.currentValue) {
      this.userName = this.user.userName;
      this.fetchUserTrustPolicy();
    }
  }

  onJsonEditorValidationError = (errors: LegacyAny) => {
    this.isJsonDataValid = errors?.length > 0 ? false : true;
  };

  onJsonDataChangeEvent = () => {
    try {
      if (this.isJsonDataValid) {
        const data = this.editor.get();
        this.onDataChanges(JSON.stringify(data));
        return;
      }
      // If json is invalid, send nothing, so that end component will know that there is no data or invalid data
      // @ts-expect-error (legacy code incremental fix)
      this.onDataChanges(undefined);
    } catch (e) {
      // ignore parse errors
    }
  };

  // @ts-expect-error (legacy code incremental fix)
  private trustPolicyHelper: TrustPolicy | null;

  updateTrustPolicyHelper() {
    this.trustPolicyHelper = TrustPolicy.fromJson(this.permissionText.edited, true);
  }

  canAddTrustedUser() {
    if (!this.exampleValueForm.valid) {
      return false;
    }
    const value = this.exampleValueForm.value;
    const destType = value.switchee === 'operator' ? 'Operator' : 'User';
    const srn =
      // @ts-expect-error (legacy code incremental fix)
      destType === 'User' ? new UserSRN(value.sourceOperator, value.user) : new OperatorSRN(value.sourceOperator);
    let srnIsValid = false;
    try {
      srnIsValid = SRN.fromString(srn.toString()) !== null;
    } catch (error) {
      srnIsValid = false;
    }
    return srnIsValid;
  }

  openAddTrustedUserModal = () => {
    this.updateTrustPolicyHelper();

    this.uiDsModalService.openConfirmModal(this.getExampleModalTemplate, {
      title: this.i['Add trusted user to trust policy'],
      okButton: (button) => {
        button.localizedTitle = this.i.Add;
        button.buttonStyle = 'primary';
        button.isDisabled_ƒ = () => !this.exampleValueForm.valid;
      },
      onOkClick: async () => {
        if (this.exampleValueForm.valid) {
          const value = this.exampleValueForm.value;
          const destType = value.switchee === 'operator' ? 'Operator' : 'User';
          const srn =
            // @ts-expect-error (legacy code incremental fix)
            destType === 'User' ? new UserSRN(value.sourceOperator, value.user) : new OperatorSRN(value.sourceOperator);

          // NOTE: The TrustPolicy object does not support all valid formats. So it might be the case that the policy is valid, but we just don't have the ability to merge the user's new trusted user into the policy. In that case, we will show the user the SRN and let them copy it themselves. It's not perfect, but it probably won't happen often. Usually, I think we will be able to update the policy automatically.
          if (this.trustPolicyHelper) {
            this.trustPolicyHelper.addAllowed(srn);
            let finalSchema = this.trustPolicyHelper.toJson();
            this.permissionText.edited = finalSchema;
            this.editorData = JSON.parse(finalSchema);
          } else {
            // we cannot update the policy, so show the error and let the user copy it themselves
            this.openCopySrnModal(srn.toString());
          }
        }
      },
    });
  };

  /**
   * Only used when we have to open the copy SRN modal because we cannot merge the SRN into the existing policy.
   */
  srnToCopy = '';

  openCopySrnModal = (srn: string) => {
    this.srnToCopy = `"${srn}"`;
    this.uiDsModalService.openConfirmModal(this.copySrnModalTemplate, {
      okButton: (button) => {
        button.localizedTitle = this.i['Done'];
      },
      hideCancelButton: true,
    });
  };

  async fetchUserTrustPolicy() {
    this.alertsManager.clear();
    this.isLoading = true;

    const params = {
      operatorId: this.operatorId,
      userName: this.userName,
    };
    try {
      const response = await this.userApiService.getUserTrustPolicy(params);
      // @ts-expect-error (legacy code incremental fix)
      const defaultPermissions = getCompactJsonText(response.data.trustPolicy);
      this.permissionText.original = defaultPermissions;
      this.permissionText.edited = defaultPermissions;
      this.editorData = !defaultPermissions ? undefined : JSON.parse(defaultPermissions);
    } catch (e) {
      console.error(e);
      this.alertsManager.add(Alert.fromApiError(e));
    } finally {
      this.updateTrustPolicyHelper();
      this.isLoading = false;
    }
  }

  isSavePermissionsButtonDisabled = () => {
    return !(
      !this.isLoading &&
      this.permissionText.edited &&
      this.permissionText.original !== this.permissionText.edited
    );
  };

  saveInlinePermissions = async () => {
    this.uiDsModalService.openConfirmModal(this.savePermissionConfirmModalTemplate, {
      title: 'security.save_trust_policy_question_mark',
      classes: ['save-policy'],
      okButton: (button) => {
        button.titleId = 'common.save';
        button.buttonStyle = 'primary';
        button.classes = ['save-permissions-confirm-button'];
      },
      showLoadingProgressOnOkClick: true,
      onOkClick: async () => {
        this.isLoading = true;
        this.alertsManager.clear();
        try {
          const params = {
            operatorId: this.operatorId,
            userName: this.userName,
            setUserTrustPolicyRequest: { trustPolicy: this.permissionText.edited },
          };

          await this.userApiService.updateUserTrustPolicy(params);
          this.permissionText.original = this.permissionText.edited;
          this.alertsManager.add(Alert.success(this.i['Trust policy updated successfully']));
        } catch (e) {
          this.alertsManager.add(Alert.fromApiError(e));
        } finally {
          this.isLoading = false;
          this.updateTrustPolicyHelper();
        }
      },
    });
  };

  deletePermissionsConfirm = async () => {
    this.uiDsModalService.openConfirmModal(this.deletePermissionConfirmModalTemplate, {
      title: this.i['Delete trust policy'],
      classes: ['revoke-authkey'],
      okButton: (button) => {
        button.titleId = 'common.delete';
        button.buttonStyle = 'danger';
        button.classes = ['delete-permissions-confirm-button'];
      },
      onOkClick: async () => {
        this.isLoading = true;
        this.alertsManager.clear();

        try {
          const params = {
            operatorId: this.operatorId,
            userName: this.userName,
          };

          await this.userApiService.deleteUserTrustPolicy(params);
          this.permissionText.edited = '{}';
          this.permissionText.original = this.permissionText.edited;
          this.editorData = JSON.parse('{}');
          this.alertsManager.add(Alert.success('Trust policy deleted successfully'));
        } catch (e) {
          this.alertsManager.add(Alert.fromApiError(e));
        } finally {
          this.isLoading = false;
          this.updateTrustPolicyHelper();
        }
      },
      modalStyle: 'delete',
      subtitle: this.i['Are you sure you want to delete this trust policy ?'],
      showLoadingProgressOnOkClick: true,
    });
  };

  onDataChanges = (data: string) => {
    this.permissionText.edited = data;
  };
}
