<details class="ds-disclosure --simple" open>
  <summary class="ds-text --large">
    {{ 'security.user_details.auth.user_auth' | translate }}
  </summary>

  <div class="ds-rows">
    <app-password-management [user]="user"></app-password-management>
    <app-user-multi-factor-auth [userName]="user?.userName" *ngIf="user?.hasPassword"></app-user-multi-factor-auth>
  </div>
</details>

<details class="ds-disclosure --simple" open>
  <summary class="ds-text --large">
    {{ 'security.user_details.auth.authkeys' | translate }}
  </summary>
  <app-auth-keys [username]="$any(user?.userName)"></app-auth-keys>
</details>
