<details class="ds-disclosure--simple" open>
  <summary class="ds-text--large">{{ 'security.user_details.delete_this' | translate }}</summary>
  <div>
    <app-alerts [alertsManager]="alertManager"></app-alerts>
    <ui-button [content]="deleteUserButton"></ui-button>
  </div>
</details>

<ng-template #deleteUserConfirmModalTemplate>
  <dl class="ds-details">
    <dt>{{ 'security.users.modals.delete.name' | translate }}</dt>
    <dd>
      <div>{{ user.userName }}</div>
    </dd>
    <dt>{{ 'security.users.modals.delete.description' | translate }}</dt>
    <dd>
      <div>{{ user.description }}</div>
    </dd>
  </dl>
</ng-template>
