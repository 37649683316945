<div class="ds-modal--loading-refresh" *ngIf="isLoading"></div>

<details class="ds-disclosure ds-disclosure--simple" open>
  <summary class="ds-text--large">{{ 'security.user_details.permissions.inline' | translate }}</summary>
  <app-permissions-editor [data]="permissionData" [onDataChanges]="$any(onDataChanges)"></app-permissions-editor>
  <app-alerts [alertsManager]="alertManager"></app-alerts>
  <ui-button [content]="savePermissionsButton"></ui-button>
  <ui-button [content]="resetPermissionButton"></ui-button>
  <ng-template #deletePermissionModalTemplate> </ng-template>
</details>

<details class="ds-disclosure ds-disclosure--simple" open>
  <summary class="ds-text--large">{{ 'security.user_details.permissions.roles' | translate }}</summary>

  <app-attach-roles
    *ngIf="showAttachRolesModal"
    [user]="user"
    (closeModal)="closeAttachRolesModal($event)"
  ></app-attach-roles>

  <ui-confirm-modal [content]="detachRoleConfirmModal">
    <div class="ds-text">
      {{ 'security.user_details.permissions.modals.role_detach.message' | translate }}
    </div>
  </ui-confirm-modal>

  <div class="ds-rows">
    <ui-button [content]="attachRolesButton"></ui-button>
    <table class="ds-datatable--embedded ds-datatable--compact">
      <thead>
        <tr>
          <th class="id">{{ 'security.user_details.permissions.table.column.id' | translate }}</th>
          <th class="description">
            {{ 'security.user_details.permissions.table.column.description' | translate }}
          </th>
          <th class="actions">
            {{ 'security.user_details.permissions.table.column.actions' | translate }}
          </th>
        </tr>
      </thead>
      <tbody *ngIf="user" [hidden]="!user.roleList.length">
        <tr
          class="attached-role --clickable"
          *ngFor="let role of user.roleList"
          [routerLink]="['/security/roles/', role.roleId]"
        >
          <td class="id">{{ role.roleId }}</td>
          <td class="description">{{ role.description }}</td>
          <td class="actions">
            <button
              class="ds-button--icon-remove ds-button--alert ds-button--plain"
              id="attached-role-remove"
              type="button"
              (click)="detachRole($event, role)"
            >
              <span>{{ 'security.user_details.permissions.detach' | translate }}</span>
            </button>
          </td>
        </tr>
      </tbody>
      <tbody [hidden]="user && user.roleList.length">
        <tr>
          <td class="--no-data" colspan="3">
            {{ 'security.user_details.permissions.no_data' | translate }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</details>
