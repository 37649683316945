<div dsDialogContent>
  <form class="ds-rows">
    <div class="ds-field">
      <ui-input [content]="username"></ui-input>
      <span class="ds-text--small" [innerHTML]="'security.users.modals.create.name_notice' | translate"></span>
    </div>
    <div class="ds-field">
      <ui-input [content]="description"></ui-input>
    </div>
  </form>
  <app-alerts [alertsManager]="alertManager"></app-alerts>
</div>

<footer dsDialogFooter>
  <ui-button-bar [content]="footerButtonBar"></ui-button-bar>
</footer>
