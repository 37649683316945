import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../../../../app/shared/core/user';
import { LegacyOptional } from '@soracom/shared/core';

@Component({
  selector: 'app-user-auth-settings',
  templateUrl: './auth-settings.component.html',
})
export class AuthSettingsComponent implements OnInit {
  @Input() user: LegacyOptional<User>;

  constructor() {}

  ngOnInit(): void {}
}
