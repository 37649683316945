<section class="ds-rows" dsDialogContent>
  <canvas id="otpQrCode" uiQRCode [qrText]="totpUri" style="margin: 0 auto"></canvas>
  <div class="ds-field">
    <div class="ds-input --copy">
      <input type="text" readonly value="{{ totpUri }}" />
      <button [appCopyButton]="totpUri" cssClass="--medium --top-left"></button>
    </div>
  </div>

  <div class="ds-text">
    {{ 'security.mfa.activationDscr' | translate }}
  </div>

  <div class="ds-field">
    <label class="ds-label" for="otpCode">
      {{ 'security.mfa.code' | translate }}
    </label>
    <input type="text" class="ds-input" id="otpCode" [formControl]="otpCodeFormControl" placeholder="123456" />
  </div>
  <app-alerts [alertsManager]="alertManager"></app-alerts>
</section>
<footer dsDialogFooter>
  <button type="button" class="ds-button --primary" (click)="verify()" [disabled]="!canVerify()"><span>{{ "security.mfa.submit" | translate }}</span></button>
  <button type="button" class="ds-button" (click)="cancel()" [disabled]="!canCancel()"><span>{{ "security.mfa.cancel" | translate }}</span></button>
</footer>
