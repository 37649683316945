import { LegacyAny } from '@soracom/shared/core';

import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { User } from '../../../../../app/shared/core/user';
import { UsersService } from '../../../../../app/shared/security/users.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiEditInPlace } from '../../../soracom-ui/ui-edit-in-place/UiEditInPlace';
import { UiTab } from '../../../soracom-ui/ui-tabs/UiTab';
import { UiTabs } from '../../../soracom-ui/ui-tabs/UiTabs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
})
export class UserDetailsComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  // @ts-expect-error (legacy code incremental fix)
  @Input() username: string;

  // @ts-expect-error (legacy code incremental fix)
  user: User;

  descriptionEditor = new UiEditInPlace();
  alertManager = new AlertsManager();

  tabs = new UiTabs();
  tabsContent = {
    permissions: UiTab.configure((uiTab) => {
      uiTab.labelId = 'security.user_details.tab.permissions';
      uiTab.spacedTabContent = false;
    }),
    trust_policy: UiTab.configure((uiTab) => {
      uiTab.labelId = 'security.tab.trust_policy';
      uiTab.spacedTabContent = true;
    }),
    auth: UiTab.configure((uiTab) => {
      uiTab.labelId = 'security.user_details.tab.auth';
      uiTab.spacedTabContent = false;
    }),
    advanced_settings: UiTab.configure((uiTab) => {
      uiTab.labelId = 'security.user_details.tab.advanced_settings';
      uiTab.spacedTabContent = false;
    }),
  };

  constructor(private usersService: UsersService, private translate: TranslateService) {
    this.tabs.tabIdPrefix = 'tab-user-details';
    this.descriptionEditor.placeholderText = this.translate.instant('security.user_details.description');
  }

  async ngOnInit() {
    await this.fetchUserDetails();
    this.descriptionEditor.text = this.user.description || '';
    // I don't know why it's subscriber instead of simple trigger function.
    this.descriptionEditor.onSave.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(this.saveDescription);
  }

  async fetchUserDetails() {
    if (!this.username) {
      return;
    }

    this.alertManager.clear();
    try {
      this.user = (await this.usersService.get(this.username)).data;
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e));
    }
  }

  saveDescription = async (description: LegacyAny) => {
    this.alertManager.clear();
    try {
      await this.usersService.update(this.username, { description });
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e));
    }
  };
}
