<div dsDialogContent>
  <div class="ds-rows">
    <div *ngIf="isError()" class="ds-notice --alert">
      <div class="ds-heading--small" style="margin-bottom: 10px">
        {{ 'security.credentials.modals.delete.is_used.header' | translate }}
      </div>
      <p class="ds-text--small">
        {{ 'security.credentials.modals.delete.is_used.description' | translate }}
      </p>
      <div *ngIf="usedOnGroupError.length > 0">
        <div class="ds-text--label">
          {{ 'security.credentials.modals.delete.is_used.group' | translate }}
        </div>
        <ul>
          <li *ngFor="let group of usedOnGroupError">
            <a href="/groups/{{ group.groupId }}?coverage_type={{ group.coverageType }}" target="_blank">
              <span>({{ getCoverageNameRef(group.coverageType) | translate }}) &nbsp;</span>
              <span *ngIf="group.groupName">{{ group.groupName }} &nbsp;</span>
              <span> id: {{ group.groupId }} </span>
            </a>
            <p class="ds-text--small">
              {{ 'security.credentials.modals.delete.is_used.being_used_service_text' | translate }}
              <span *ngFor="let service of group.services">&nbsp; {{ service }}</span>
            </p>
          </li>
        </ul>
      </div>
      <div *ngIf="usedOnEventHandlerError.length > 0">
        <div class="ds-text--label">
          {{ 'security.credentials.modals.delete.is_used.event_handler' | translate }}
        </div>
        <ul>
          <li *ngFor="let eventHandler of usedOnEventHandlerError">
            <a
              href="/event_handler/edit_event/{{ eventHandler.id }}?coverage_type={{ eventHandler.coverageType }}"
              target="_blank"
            >
              <span>({{ getCoverageNameRef(eventHandler.coverageType) | translate }}) &nbsp;</span>
              {{ eventHandler.name }}
              <span *ngIf="eventHandler.description">&nbsp; {{ eventHandler.description }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <dl class="ds-details">
    <dt>{{ 'security.credentials.modals.delete.credentials_id' | translate }}</dt>
    <dd>
      <div>{{ data.credentialsId }}</div>
    </dd>
    <dt>{{ 'security.credentials.modals.delete.description' | translate }}</dt>
    <dd>
      <div>{{ data.description }}</div>
    </dd>
  </dl>
</div>
<footer dsDialogFooter>
  <span>
    <ui-button [content]="closeButton"></ui-button>
    <ui-button [content]="okButton"></ui-button>
  </span>
</footer>
