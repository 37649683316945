import { LegacyAny } from '@soracom/shared/core';

import { Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { LoginUserDataService } from '@soracom/shared/data-access-auth';
import { SoracomApiService } from '../../../../../app/shared/components/soracom_api.service';
import { Role } from '../../../../../app/shared/core/role';
import { User } from '../../../../../app/shared/core/user';
import { UserRolesService } from '../../../../../app/shared/security/user_roles.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiConfirmModal } from '../../../soracom-ui/ui-confirm-modal/UiConfirmModal';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { getCompactJsonText } from '../../roles/role-permissions/role-permissions.component';

@Component({
  selector: 'app-user-permissions',
  templateUrl: './user-permissions.component.html',
})
export class UserPermissionsComponent implements OnInit {
  @ViewChild('deletePermissionModalTemplate', { static: true, read: TemplateRef })
  // @ts-expect-error (legacy code incremental fix)
  deletePermissionModalTemplate: TemplateRef<any>;
  // @ts-expect-error (legacy code incremental fix)
  @Input() user: User;

  alertManager = new AlertsManager();
  savePermissionsButton = new UiButton();
  resetPermissionButton = new UiButton();
  attachRolesButton = new UiButton();
  // @ts-expect-error (legacy code incremental fix)
  detachRoleConfirmModal: UiConfirmModal;

  isLoading = false;
  text = {
    original: '',
    editing: '',
  };
  // This extra variable is here, to pass it to permission editor component. It is to make it one way update, from this component -> permission editor. If we pass text.editing to permission editor, when user edits text in editor, it will try to update text.editing, and text.editing will try to update editor, so cursor position get reset! Its two way binding, so preventing that by this variable.
  permissionData = '';
  // @ts-expect-error (legacy code incremental fix)
  operatorId: string;
  showAttachRolesModal = false;

  constructor(
    private soracomApiService: SoracomApiService,
    private loginUserDataService: LoginUserDataService,
    private userRolesService: UserRolesService,
    private uiDsModalService: UiDsModalService
  ) {
    this.setupComponents();
  }

  ngOnInit(): void {
    // @ts-expect-error (legacy code incremental fix)
    this.operatorId = this.loginUserDataService.getLoginUser()?.operatorId;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.user?.currentValue) {
      if (!this.user.permission) {
        this.user.permission = '';
      }

      this.text.original = this.user.permission;
      this.text.editing = this.user.permission;
      this.permissionData = this.user.permission;
      this.userRolesService.userName = this.user.userName;
    }
  }

  setupComponents() {
    this.savePermissionsButton.titleId = 'security.user_details.permissions.save';
    this.savePermissionsButton.buttonStyle = 'primary';
    this.savePermissionsButton.isDisabled_ƒ = () => !this.canSavePermissions();
    this.savePermissionsButton.onClick = this.savePermissions;

    this.resetPermissionButton.titleId = 'security.user_details.permissions.delete';
    this.resetPermissionButton.iconName = 'icon-delete';
    this.resetPermissionButton.buttonStyle = 'danger';
    this.resetPermissionButton.onClick = this.openDeletePermissionModal;
    this.resetPermissionButton.isDisabled_ƒ = () => !this.text.original || this.text.original === '{}';

    this.attachRolesButton.titleId = 'security.user_details.permissions.attach';
    this.attachRolesButton.buttonStyle = 'primary';
    this.attachRolesButton.iconName = 'icon-plus';
    this.attachRolesButton.onClick = this.attachRoles;
  }

  canSavePermissions = () => {
    return (
      !this.isLoading &&
      this.text.editing &&
      getCompactJsonText(this.text.original) !== getCompactJsonText(this.text.editing)
    );
  };

  savePermissions = async () => {
    this.isLoading = true;
    this.alertManager.clear();

    try {
      await this.soracomApiService.setUserPermissions(this.operatorId, this.user.userName, {
        permission: this.text.editing,
      });
      this.text.original = this.text.editing;
      this.user.permission = this.text.editing;
      this.alertManager.add(Alert.success('security.user_details.permissions.save_success'));
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e));
    } finally {
      this.isLoading = false;
    }
  };

  attachRoles = () => {
    this.showAttachRolesModal = true;
  };

  detachRole($event: LegacyAny, role: Role) {
    $event.stopPropagation();
    this.detachRoleConfirmModal = UiConfirmModal.configure((modal) => {
      modal.titleId = 'security.user_details.permissions.modals.role_detach.header';
      modal.showLoadingProgressOnOkClick = true;
      modal.okButton.buttonStyle = 'primary';
      modal.okButton.titleId = 'security.user_details.permissions.modals.role_detach.submit';
      modal.onOkClick = () => this.onDetachRole(role);
    });
    this.detachRoleConfirmModal.open();
  }

  onDetachRole = async (role: Role) => {
    try {
      await this.userRolesService.detach(role.roleId);
      // remove role from list too
      this.user.roleList.splice(
        this.user.roleList.findIndex((userRole) => userRole.roleId === role.roleId),
        1
      );
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e));
    }
  };

  closeAttachRolesModal(newRoles: Role[] | null) {
    this.showAttachRolesModal = false;

    if (newRoles) {
      this.user.roleList = [...newRoles, ...this.user.roleList];
    }
  }

  onDataChanges = (data: string) => {
    this.text.editing = data;
  };

  deletePermission = async () => {
    // Reset Permission
    this.text.editing = '{}';
    this.isLoading = true;
    this.alertManager.clear();
    try {
      await this.soracomApiService.deleteUserPermissions(this.operatorId, this.user.userName);
      this.text.original = this.text.editing;
      this.user.permission = this.text.editing;
      this.permissionData = this.text.editing;
      this.alertManager.add(Alert.success('security.user_details.permissions.delete_success'));
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e));
    } finally {
      this.isLoading = false;
    }
  };

  openDeletePermissionModal = async () => {
    this.uiDsModalService.openConfirmModal(this.deletePermissionModalTemplate, {
      title: 'security.user_details.permissions.modals.deletePermission.title',
      okButton: (button) => {
        button.titleId = 'common.delete';
        button.buttonStyle = 'danger';
        button.classes = ['delete-user-permission-button'];
      },
      onOkClick: this.deletePermission,
      modalStyle: 'delete',
      subtitle: 'security.user_details.permissions.modals.deletePermission.subtitle',
      showLoadingProgressOnOkClick: true,
    });
  };
}
