import { Component, OnInit } from '@angular/core';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { CredentialsModel } from '@soracom/shared/soracom-api-typescript-client';

@Component({
  templateUrl: './credential-details.component.html',
})
export class CredentialDetailsComponent extends DsModalContentBase<CredentialsModel, null> implements OnInit {
  closeButton = new UiButton();

  data = this.getInput();

  constructor() {
    super();
    this.closeButton.titleId = 'security.credentials.modals.show.close';
    this.closeButton.onClick = () => this.close();
  }

  ngOnInit(): void {}

  formattedCredentials() {
    if (this.data.credentials) {
      return JSON.stringify(this.data.credentials, null, 2);
    }
  }
}
