import { Component, OnInit } from '@angular/core';

import { User } from '../../../../app/shared/core/user';
import { UsersService } from '../../../../app/shared/security/users.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { AddUserComponent } from './add-user/add-user.component';

@Component({
  selector: 'app-sam-users',
  templateUrl: './sam-users.component.html',
})
export class SamUsersComponent implements OnInit {
  isLoading = false;
  // @ts-expect-error (legacy code incremental fix)
  users: User[];

  alertManager = new AlertsManager();
  addUserButton: UiButton;

  constructor(private usersService: UsersService, private uiDsModalService: UiDsModalService) {
    this.addUserButton = UiButton.configure((button) => {
      button.titleId = 'security.users.create';
      button.buttonStyle = 'primary';
      button.classes = ['user-add-button'];
      button.onClick = this.showAddUserModal;
    });
  }

  async ngOnInit() {
    await this.updateUserList();
  }

  async updateUserList() {
    this.isLoading = true;
    this.alertManager.clear();
    try {
      this.users = (await this.usersService.list()).data;
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e));
    } finally {
      this.isLoading = false;
    }
  }

  showAddUserModal = async () => {
    // @ts-expect-error (legacy code incremental fix)
    const result: { isCancel: boolean } = await this.uiDsModalService.openAndWaitForResult(AddUserComponent, {});

    if (!result?.isCancel) {
      this.updateUserList();
    }
  };
}
