<details class="ds-disclosure--simple" open>
  <summary class="ds-text--large">{{ 'security.role_details.delete_this' | translate }}</summary>
  <div>
    <app-alerts [alertsManager]="alertManager"></app-alerts>
    <ui-button [content]="deleteRoleButton"></ui-button>
  </div>
</details>
<ng-template #deleteRoleConfirmModalTemplate>
  <dl class="ds-details">
    <dt>{{ 'security.role_details.modals.delete.role_id' | translate }}</dt>
    <dd>
      <div>{{ role.roleId }}</div>
    </dd>
    <dt>{{ 'security.role_details.modals.delete.description' | translate }}</dt>
    <dd>
      <div>{{ role.description }}</div>
    </dd>
  </dl>
</ng-template>
