<div class="ds-rows">
  <dl class="ds-details">
    <dt>{{ 'security.mfa.mfa' | translate }}</dt>
    @if (isMfaActive) {
      <dd>
        <div>{{ 'security.mfa.enabled' | translate }}</div>
        <button type="button" class="ds-button --plain --alert --icon-delete --hide-label --top-left" (click)="showDisableMfaConfirmModal()">
          <span>{{ 'security.mfa.inactivate' | translate }}</span>
        </button>
      </dd>
    } @else {
      <dd>
        <div>{{ 'security.mfa.disabled' | translate }}</div>
        <button type="button" class="ds-button --plain --primary --icon-plus --hide-label --top-left" (click)="activateMfa()">
          <span>{{ 'security.mfa.activate' | translate }}</span>
        </button>
      </dd>
    }
  </dl>
  <app-alerts [alertsManager]="alertManager"></app-alerts>
</div>
