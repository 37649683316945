import { LegacyAny } from '@soracom/shared/core';

import { Component, Inject, OnInit, inject } from '@angular/core';
import { getLoginUserData } from '@soracom/shared/data-access-auth';
import Clipboard from 'clipboard';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { MODAL_DATA, UiDsModalRef } from '@soracom/shared-ng/ui-ds-modal';
import { OperatorApiService, UserApiService } from '@soracom/shared-ng/soracom-api-ng-client';

@Component({
  templateUrl: './generate-auth-key.component.html',
})
export class GenerateAuthKeyComponent implements OnInit {
  username: string;

  alertManager = new AlertsManager();
  clipboard: LegacyAny;

  isLoading = false;
  authKeyData: LegacyAny;

  private operatorApiService = inject(OperatorApiService);
  private userApiService = inject(UserApiService);

  constructor(public modalRef: UiDsModalRef, @Inject(MODAL_DATA) public data: LegacyAny) {
    this.username = data?.username;
  }

  async ngOnInit() {
    this.clipboard = new Clipboard('.cc-auth');
    this.clipboard.on('success', this.authCopied);
    this.clipboard.on('error', this.authCopyError);
    await this.generateAuthKey();
  }

  ngOnDestroy(): void {
    this.clipboard.destroy();
  }

  async generateAuthKey() {
    try {
      this.isLoading = true;
      this.alertManager.clear();
      const { operatorId } = getLoginUserData();

      const response = !this.username
        ? await this.operatorApiService.generateOperatorAuthKey({ operatorId: operatorId })
        : await this.userApiService.generateUserAuthKey({ operatorId: operatorId, userName: this.username });
      this.authKeyData = response.data;
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e));
    } finally {
      this.isLoading = false;
    }
  }

  canCopy() {
    return this.authKeyData;
  }

  close = () => {
    this.modalRef.close(this.authKeyData);
  };

  textToCopy() {
    if (!this.authKeyData) {
      return '';
    }

    if (!this.username) {
      return `authKeyId,authKeySecret\n${this.authKeyData.authKeyId},${this.authKeyData.authKey}`;
    } else {
      return `user,authKeyId,authKeySecret\n${this.username},${this.authKeyData.authKeyId},${this.authKeyData.authKey}`;
    }
  }

  authCopied = () => {
    this.alertManager.add(Alert.success('security.auth_keys.modals.create.copied'));
  };

  authCopyError = () => {
    this.alertManager.add(Alert.danger('security.auth_keys.modals.create.copy_failed'));
  };
}
