import { LegacyAny } from '@soracom/shared/core';

import {
  LegacyBaseSoracomApiService,
  PaginatableService,
  SoracomApiParams,
} from '@user-console/legacy-soracom-api-client';

import { SearchQuery } from '@user-console/legacy-soracom-api-client';
import { PaginationOptions, ScRelation } from '../components/paginator';
import { InjectList } from '../core/injectable';
import { User } from '../core/user';
import { LoginUserDataService } from '@soracom/shared/data-access-auth';

export interface CreateUserParams {
  description: string;
}

export interface UpdateUserParams {
  description: string;
}

export interface UpdateUserPasswordParams {
  currentPassword: string;
  newPassword: string;
}

export class UsersService implements PaginatableService<User> {
  static $inject: InjectList = ['BaseSoracomApiService', 'LoginUserDataService'];

  resourcePath = 'users';

  constructor(
    private soracomApi: LegacyBaseSoracomApiService,
    private loginUserDataService: LoginUserDataService,
  ) {}

  get basePath(): string {
    return `/v1/operators/${this.loginUserDataService.getLoginUser()?.operatorId}/${this.resourcePath}`;
  }

  list(paginationOptions: PaginationOptions = { limit: 1000 }, _searchQuery?: SearchQuery): Promise<ScRelation<User>> {
    const apiParams: SoracomApiParams = {
      path: this.basePath,
      query: { ...paginationOptions },
    };

    return this.soracomApi.callApiWithToken(apiParams).then((res: LegacyAny) => {
      return this.soracomApi.generateScRelation<User>(
        res.data.map((d: LegacyAny) => new User(d)),
        res,
      );
    });
  }

  create(userName: string, userParams: CreateUserParams) {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `${this.basePath}/${userName}`,
      contentType: 'application/json',
      body: userParams,
    };
    return this.soracomApi.callApiWithToken(apiParams);
  }

  get(userName: string) {
    const apiParams: SoracomApiParams = {
      path: `${this.basePath}/${userName}`,
    };
    return this.soracomApi.callApiWithToken(apiParams);
  }

  update(userName: string, userParams: UpdateUserParams) {
    const apiParams: SoracomApiParams = {
      method: 'put',
      path: `${this.basePath}/${userName}`,
      contentType: 'application/json',
      body: userParams,
    };
    return this.soracomApi.callApiWithToken(apiParams);
  }

  delete(userName: string) {
    const apiParams: SoracomApiParams = {
      method: 'delete',
      path: `${this.basePath}/${userName}`,
    };
    return this.soracomApi.callApiWithToken(apiParams);
  }

  createPassword(userName: string, password: string) {
    const params = {
      password,
    };

    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `${this.basePath}/${userName}/password`,
      contentType: 'application/json',
      body: params,
    };
    return this.soracomApi.callApiWithToken(apiParams);
  }

  updatePassword(userName: string, params: UpdateUserPasswordParams) {
    const apiParams: SoracomApiParams = {
      method: 'put',
      path: `${this.basePath}/${userName}/password`,
      contentType: 'application/json',
      body: params,
    };
    return this.soracomApi.callApiWithToken(apiParams);
  }

  deletePassword(userName: string) {
    const apiParams: SoracomApiParams = {
      method: 'delete',
      path: `${this.basePath}/${userName}/password`,
    };
    return this.soracomApi.callApiWithToken(apiParams);
  }
}
