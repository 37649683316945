import { Component, Input, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from '../../../../../app/shared/core/role';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { RoleApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { getLoginUserData } from '@soracom/shared/data-access-auth';

@Component({
  selector: 'app-role-advance-settings',
  templateUrl: './role-advance-settings.component.html',
})
export class RoleAdvanceSettingsComponent implements OnInit {
  // @ts-expect-error (legacy code incremental fix)
  @Input() role: Role;
  @ViewChild('deleteRoleConfirmModalTemplate', { static: true, read: TemplateRef })
  // @ts-expect-error (legacy code incremental fix)
  deleteRoleConfirmModalTemplate: TemplateRef<any>;

  alertManager = new AlertsManager();
  deleteRoleButton = new UiButton();
  private roleApi = inject(RoleApiService);

  constructor(private router: Router, private uiDsModalService: UiDsModalService) {
    this.deleteRoleButton = UiButton.configure((button) => {
      button.titleId = 'security.role_details.delete_this';
      button.buttonStyle = 'danger';
      button.onClick = this.showDeleteRoleConfirmModal;
    });
  }

  ngOnInit(): void {}

  showDeleteRoleConfirmModal = () => {
    this.uiDsModalService.openConfirmModal(this.deleteRoleConfirmModalTemplate, {
      title: 'security.role_details.modals.delete.header',
      okButton: (button) => {
        button.titleId = 'security.role_details.modals.delete.submit';
        button.buttonStyle = 'danger';
        button.classes = ['delete-role-confirm-button'];
      },
      modalStyle: 'delete',
      subtitle: 'security.role_details.modals.delete.message',
      onOkClick: this.deleteRole,
      showLoadingProgressOnOkClick: true,
    });
  };

  deleteRole = async () => {
    this.alertManager.clear();
    try {
      await this.roleApi.deleteRole({
        roleId: this.role.roleId,
        operatorId: getLoginUserData().operatorId,
      });
      this.router.navigate(['/security', 'roles']);
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e, 'security.unknown_error'));
    }
  };
}
