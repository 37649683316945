import { Component, Input, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../../../../../app/shared/core/user';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiConfirmModal } from '../../../../soracom-ui/ui-confirm-modal/UiConfirmModal';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { getLoginUserData } from '@soracom/shared/data-access-auth';
import { UserApiService } from '@soracom/shared-ng/soracom-api-ng-client';

@Component({
  selector: 'app-user-advance-settings',
  templateUrl: './advance-settings.component.html',
})
export class AdvanceSettingsComponent implements OnInit {
  // @ts-expect-error (legacy code incremental fix)
  @Input() user: User;
  @ViewChild('deleteUserConfirmModalTemplate', { static: true, read: TemplateRef })
  // @ts-expect-error (legacy code incremental fix)
  deleteUserConfirmModalTemplate: TemplateRef<any>;

  deleteUserButton: UiButton;
  alertManager = new AlertsManager();

  // @ts-expect-error (legacy code incremental fix)
  confirmDeleteUserModal: UiConfirmModal;

  private userApi = inject(UserApiService);

  constructor(private router: Router, private uiDsModalService: UiDsModalService) {
    this.deleteUserButton = UiButton.configure((button) => {
      button.titleId = 'security.user_details.delete_this';
      button.buttonStyle = 'danger';
      button.onClick = this.openConfirmDeleteUserModal;
    });
  }

  ngOnInit(): void {}

  openConfirmDeleteUserModal = () => {
    this.uiDsModalService.openConfirmModal(this.deleteUserConfirmModalTemplate, {
      title: 'security.users.modals.delete.header',
      classes: ['delete-user'],
      okButton: (button) => {
        button.titleId = 'security.users.modals.delete.submit';
        button.buttonStyle = 'danger';
        button.classes = ['delete-user-confirm-button'];
      },
      modalStyle: 'delete',
      subtitle: 'security.users.modals.delete.message',
      onOkClick: this.deleteUser,
      showLoadingProgressOnOkClick: true,
    });
  };

  deleteUser = async () => {
    try {
      this.alertManager.clear();
      await this.userApi.deleteUser({
        userName: this.user.userName,
        operatorId: getLoginUserData().operatorId,
      });
      this.router.navigate(['/security/users']);
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e, 'security.unknown_error'));
    }
  };
}
