<form>
  <section dsDialogContent>
    <p>
      {{ 'security.mfa.askDisable' | translate }}
    </p>
    <div class="ds-rows">
      <div class="ds-text">
        <p>
          {{ 'security.mfa.enter_password' | translate }}
        </p>
      </div>
      <div class="ds-field">
        <label class="ds-text --label"> {{ 'common.password' | translate }} </label>
        <div class="ds-input">
          <input
            [(ngModel)]="inputPassword"
            name="'input-password'"
            type="password"
            placeholder="{{ 'common.enter_password' | translate }}"
            maxlength="100"
          />
        </div>
      </div>
    </div>
  </section>
  <footer dsDialogFooter>
    <button class="ds-button --primary --alert x-send" type="submit" (click)="submit()"
            [disabled]="inputPassword.length < 1">
      <span [innerHTML]="'security.mfa.submit_inactivate' | translate"></span>
    </button>
    <button class="ds-button" type="button" (click)="close()">
      <span [innerHTML]="'security.mfa.cancel' | translate"></span>
    </button>
  </footer>
</form>
