import { Component, Input, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getLoginUserData } from '@soracom/shared/data-access-auth';
import { CoverageTypeService } from '@soracom/shared/data-access-auth';
import { User } from '../../../../../../app/shared/core/user';
import { environment } from '../../../../../environments/environment';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { LegacyAny, LegacyOptional } from '@soracom/shared/core';
import { UserApiService } from '@soracom/shared-ng/soracom-api-ng-client';

@Component({
  selector: 'app-password-management',
  templateUrl: './password-management.component.html',
})
export class PasswordManagementComponent implements OnInit {
  @Input() user: LegacyOptional<User>;
  @ViewChild('removePasswordConfirmModalTemplate', { static: true, read: TemplateRef })
  removePasswordConfirmModalTemplate: LegacyOptional<TemplateRef<any>>;

  alertManager = new AlertsManager();

  showEditPasswordModal = false;
  passwordOperation: LegacyAny;

  private userApi = inject(UserApiService);

  constructor(
    private translate: TranslateService,
    private coverageTypeService: CoverageTypeService,
    private uiDsModalService: UiDsModalService,
  ) {}

  ngOnInit(): void {}

  get samLoginUrl() {
    const ssoUrl = environment.authSSOUrl;
    const operatorCoverageTypes = getLoginUserData().operatorData.getCoverageTypes();
    const hasMultipleCoverageTypes = operatorCoverageTypes && operatorCoverageTypes.length > 1;

    const operatorId = getLoginUserData().operatorId;

    if (hasMultipleCoverageTypes) {
      return `${ssoUrl}/login/samlogin?o=${operatorId}&coverage_type=${this.coverageTypeService.getCoverageType()}`;
    } else {
      return `${ssoUrl}/login/samlogin?o=${operatorId}`;
    }
  }

  createPassword = () => {
    this.showEditPasswordModal = true;
    this.passwordOperation = 'create';
  };

  updatePassword = () => {
    this.showEditPasswordModal = true;
    this.passwordOperation = 'update';
  };

  closeEditPasswordModal = (wasSaved = false) => {
    this.showEditPasswordModal = false;
    this.passwordOperation = '';

    if (wasSaved) {
      // @ts-expect-error (legacy code incremental fix)
      this.user.hasPassword = true;
    }
  };

  showRemovePasswordConfirm = () => {
    // @ts-expect-error (legacy code incremental fix)
    this.uiDsModalService.openConfirmModal(this.removePasswordConfirmModalTemplate, {
      title: 'security.user_details.auth.modals.password_delete.header',
      classes: ['remove-password'],
      okButton: (button) => {
        button.titleId = 'common.remove';
        button.buttonStyle = 'danger';
        button.classes = ['remove-password-confirm-button'];
      },
      modalStyle: 'remove',
      onOkClick: this.removePassword,
      showLoadingProgressOnOkClick: true,
    });
  };

  removePassword = async () => {
    try {
      await this.userApi.deleteUserPassword({
        userName: this.user!.userName,
        operatorId: getLoginUserData().operatorId,
      });
      // @ts-expect-error (legacy code incremental fix)
      this.user.hasPassword = false;
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e, 'security.unknown_error'));
    }
  };
}
