import { LegacyAny } from '@soracom/shared/core';

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { useWithGlobalLoading } from '@soracom/shared-ng/ui-sds';
import { getLoginUserData } from '@soracom/shared/data-access-auth';
import * as qrCode from 'qrcode';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButtonBar } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiDsModalRef } from '@soracom/shared-ng/ui-ds-modal';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { UiInput } from '../../soracom-ui/ui-input/UiInput';
import { DeactivateMfaModalComponent } from './deactivate-mfa-modal.component';
import { OperatorApiService } from '@soracom/shared-ng/soracom-api-ng-client';

@Component({
  selector: 'app-multi-factor-auth',
  templateUrl: './multi-factor-auth.component.html',
})
export class MultiFactorAuthComponent implements OnInit {
  inactivateMfaButton: UiButton;
  activateMfaButton: UiButton;
  alertManager = new AlertsManager();
  verifyOtpAlertManager = new AlertsManager();
  withLoading = useWithGlobalLoading();

  isMfaActive = false;
  // @ts-expect-error (legacy code incremental fix)
  totpUri: string;

  // @ts-expect-error (legacy code incremental fix)
  @ViewChild('activateMfaModal', { static: true, read: TemplateRef }) activateMfaModalTemplateRef: TemplateRef<any>;
  activateMfaModalFooterButtonBar: UiButtonBar;
  isLoading = false;
  isVerified = false;
  otpCode: UiInput;
  otpCodeFormControl = new FormControl('', [Validators.required, Validators.pattern(/^\d+$/)]);
  // @ts-expect-error (legacy code incremental fix)
  activateMfaModalRef: UiDsModalRef;
  private operatorApi = inject(OperatorApiService);

  @ViewChild('afterActivatedModal', { static: true, read: TemplateRef })
  // @ts-expect-error (legacy code incremental fix)
  afterActivateModalTemplateRef: TemplateRef<any>;
  // @ts-expect-error (legacy code incremental fix)
  afterActivateModalRef: UiDsModalRef;
  afterActivatedModalCloseButton: UiButtonBar;

  constructor(private uiDsModalService: UiDsModalService) {
    this.inactivateMfaButton = UiButton.configure((button) => {
      button.titleId = 'security.mfa.inactivate';
      button.buttonStyle = 'danger';
      button.onClick = async () => {
        this.alertManager.clear();
        await this.uiDsModalService
          .openAndWaitForResult(DeactivateMfaModalComponent, {
            title: 'security.mfa.inactivate',
          })
          .then((result: LegacyAny) => {
            if (result?.password) {
              this.deactivateMfa(result.password);
            }
          });
      };
    });

    this.activateMfaButton = UiButton.configure((button) => {
      button.titleId = 'security.mfa.activate';
      button.buttonStyle = 'primary';
      button.onClick = this.openActivateMfaModal;
    });

    this.activateMfaModalFooterButtonBar = UiButtonBar.configure((bar) => {
      bar.rightButtons = [
        UiButton.configure((button) => {
          button.titleId = 'security.mfa.cancel';
          button.onClick = () => this.activateMfaModalRef.close();
          button.isDisabled_ƒ = this.closeDisabled;
        }),
        UiButton.configure((button) => {
          button.titleId = 'security.mfa.submit';
          button.buttonStyle = 'primary';
          button.onClick = this.verify;
          button.isDisabled_ƒ = () => !this.otpCodeFormControl.valid || this.isLoading || this.isVerified;
        }),
      ];
    });

    this.otpCode = UiInput.configure((input) => {
      input.placeholder = '123456';
      input.formControl = this.otpCodeFormControl;
    });

    this.afterActivatedModalCloseButton = UiButtonBar.configure((bar) => {
      bar.rightButtons = [
        UiButton.configure((button) => {
          button.titleId = 'security.mfa.close';
          button.onClick = () => this.afterActivateModalRef.close();
        }),
      ];
    });
  }

  async ngOnInit() {
    await this.fetchMfaStatus();
  }

  get operatorId() {
    return getLoginUserData().operatorId;
  }

  async fetchMfaStatus() {
    try {
      const response = await this.operatorApi.getMFAStatus({ operatorId: this.operatorId });
      this.isMfaActive = this.checkIsMfaActive(response.data.status);
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e, 'security.unknown_error'));
    }
  }

  checkIsMfaActive(state?: string) {
    return state === 'ACTIVE';
  }

  openActivateMfaModal = async () => {
    try {
      this.alertManager.clear();
      this.verifyOtpAlertManager.clear();
      this.isLoading = false;
      this.isVerified = false;
      this.otpCode.value = '';
      this.otpCodeFormControl.markAsPristine();
      this.otpCodeFormControl.markAsUntouched();
      const response = await this.operatorApi.enableMFA({ operatorId: this.operatorId });
      this.totpUri = response.data.totpUri;

      this.activateMfaModalRef = this.uiDsModalService.open(this.activateMfaModalTemplateRef, {
        title: 'security.mfa.activate',
        data: {
          ...{ totpUri: response.data.totpUri },
        },
      });
      this.activateMfaModalRef.disableEscKeyClose = this.closeDisabled;

      this.showQrCode();
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e, 'security.unknown_error'));
    }
  };

  showQrCode = () => {
    qrCode.toCanvas(document.getElementById('otpQrCode'), this.totpUri, (error: LegacyAny) => {
      if (error) {
        this.alertManager.add(Alert.danger(error));
      }
    });
  };

  verify = async () => {
    try {
      this.verifyOtpAlertManager.clear();
      this.isLoading = true;
      const response = await this.operatorApi.verifyMFA({
        operatorId: this.operatorId,
        mFAAuthenticationRequest: { mfaOTPCode: this.otpCode.value },
      });
      this.alertManager.add(Alert.success('security.mfa.activated'));
      this.isVerified = true;
      this.isMfaActive = true;
      this.activateMfaModalRef.close();

      this.afterActivateModalRef = this.uiDsModalService.open(this.afterActivateModalTemplateRef, {
        title: 'security.mfa.activated',
        data: [...(response.data.backupCodes || [])],
      });
    } catch (e) {
      this.verifyOtpAlertManager.add(Alert.fromApiError(e, 'security.unknown_error'));
    } finally {
      this.isLoading = false;
    }
  };

  deactivateMfa = async (password: string) => {
    try {
      this.alertManager.clear();
      await this.withLoading(
        this.operatorApi.revokeMFA({ operatorId: this.operatorId, mFARevokeRequest: { password } })
      );
      this.alertManager.add(Alert.success('security.mfa.inactivated'));
      this.isMfaActive = false;
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        this.alertManager.add(Alert.fromApiError(e.error, 'security.unknown_error'));
      } else {
        this.alertManager.add(Alert.fromApiError(e, 'security.unknown_error'));
      }
    }
  };

  private closeDisabled = () => this.isLoading || this.isVerified;
}
