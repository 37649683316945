<app-alerts [alertsManager]="alertManager"></app-alerts>

<div class="ds-rows">
  <dl class="ds-details">
    <dt>{{ 'security.user_details.header' | translate }}</dt>
    <dd>
      <div>{{ username }}</div>
    </dd>
    <dt>{{ 'security.user_details.description' | translate }}</dt>
    <dd>
      <ui-edit-in-place [content]="descriptionEditor"></ui-edit-in-place>
    </dd>
  </dl>
  <ui-tabs [content]="tabs" class="--no-indent">
    <ui-tab [content]="tabsContent.permissions">
      <app-user-permissions [user]="user"></app-user-permissions>
    </ui-tab>
    <ui-tab [content]="tabsContent.trust_policy" *appFeature="'trustPolicy'">
      <app-trust-policy-config [user]="user"></app-trust-policy-config>
    </ui-tab>
    <ui-tab [content]="tabsContent.auth">
      <app-user-auth-settings [user]="user"></app-user-auth-settings>
    </ui-tab>
    <ui-tab [content]="tabsContent.advanced_settings">
      <app-user-advance-settings [user]="user"></app-user-advance-settings>
    </ui-tab>
  </ui-tabs>
</div>