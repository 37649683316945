import { LegacyAny } from '@soracom/shared/core';

import { Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getLoginUserData } from '@soracom/shared/data-access-auth';
import { formatDateTime } from '@soracom/shared/util-common';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { GenerateAuthKeyComponent } from '../generate-auth-key/generate-auth-key.component';
import { OperatorApiService, UserApiService } from '@soracom/shared-ng/soracom-api-ng-client';

@Component({
  selector: 'app-auth-keys',
  templateUrl: './auth-keys.component.html',
})
export class AuthKeysComponent implements OnInit {
  // @ts-expect-error (legacy code incremental fix)
  @Input() username: string;
  // @ts-expect-error (legacy code incremental fix)
  @ViewChild('revokeKeyModalTemplate', { static: true, read: TemplateRef }) revokeKeyModalTemplate: TemplateRef<any>;

  private operatorApi = inject(OperatorApiService);
  private userApi = inject(UserApiService);

  generateAuthKeyButton = new UiButton();
  alertManager = new AlertsManager();
  private hasPermission = true;

  authKeyList: LegacyAny;
  // @ts-expect-error (legacy code incremental fix)
  authKeyIdToDelete: string;

  constructor(private translate: TranslateService, private uiDsModalService: UiDsModalService) {
    this.generateAuthKeyButton = UiButton.configure((button) => {
      button.titleId = 'security.user_details.auth.generate';
      button.buttonStyle = 'primary';
      button.iconName = 'icon-password';
      button.onClick = this.generateAuthKey;
      button.classes = ['auth-key-add-button'];
      button.isDisabled_ƒ = () => this.authKeyList?.length >= 2 || !this.hasPermission;
    });
  }

  ngOnInit(): void {
    if (!this.username) {
      this.fetchAuthKeys();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.username?.currentValue) {
      this.fetchAuthKeys();
    }
  }

  async fetchAuthKeys() {
    this.hasPermission = true;
    try {
      this.alertManager.clear();
      this.authKeyList = !this.username
        ? (await this.operatorApi.listOperatorAuthKeys({ operatorId: this.operatorId })).data
        : (await this.userApi.listUserAuthKeys({ operatorId: this.operatorId, userName: this.username })).data;
    } catch (e: LegacyAny) {
      if (e?.data?.code === 'COM0017') {
        this.hasPermission = false;
      }
      this.alertManager.add(Alert.fromApiError(e));
    }
  }

  generateAuthKey = () => {
    this.uiDsModalService
      .openAndWaitForResult(GenerateAuthKeyComponent, {
        title: 'security.auth_keys.modals.create.header',
        data: {
          username: this.username,
        },
      })
      .then(async () => {
        // fetch authkeys latest data
        const response = this.username
          ? await this.userApi.listUserAuthKeys({ operatorId: this.operatorId, userName: this.username })
          : await this.operatorApi.listOperatorAuthKeys({ operatorId: this.operatorId });
        this.authKeyList = [...response.data];
      });
  };

  formatLastUsedDateTime(unixSec: LegacyAny) {
    if (unixSec === undefined || unixSec === null) {
      return this.translate.instant('security.user_details.auth.not_used');
    }

    return formatDateTime(unixSec * 1000, 'datetime');
  }

  formatCreatedDateTime(unixSec: LegacyAny) {
    return formatDateTime(unixSec * 1000, 'datetime');
  }

  revokeAuthKeyConfirm(authKey: LegacyAny) {
    this.authKeyIdToDelete = authKey.authKeyId;
    this.uiDsModalService.openConfirmModal(this.revokeKeyModalTemplate, {
      title: 'security.user_details.auth.modals.authkey.revoke.header',
      classes: ['revoke-authkey'],
      okButton: (button) => {
        button.titleId = 'security.user_details.auth.modals.authkey.revoke.submit';
        button.buttonStyle = 'danger';
        button.classes = ['revoke-authkey-confirm-button'];
      },
      subtitle: 'security.user_details.auth.modals.authkey.revoke.message',
      modalStyle: 'alert',
      onOkClick: this.revokeAuthKey,
      data: {
        authKeyIdToDelete: authKey.authKeyId,
      },
      showLoadingProgressOnOkClick: true,
    });
  }

  get operatorId() {
    return getLoginUserData().operatorId;
  }

  revokeAuthKey = async () => {
    try {
      this.alertManager.clear();

      if (this.username) {
        await this.userApi.deleteUserAuthKey({
          operatorId: this.operatorId,
          userName: this.username,
          authKeyId: this.authKeyIdToDelete,
        });
      } else {
        await this.operatorApi.deleteOperatorAuthKey({
          operatorId: this.operatorId,
          authKeyId: this.authKeyIdToDelete,
        });
      }

      // Also delete from user object
      this.authKeyList.splice(
        this.authKeyList.findIndex((key: LegacyAny) => key.authKeyId === this.authKeyIdToDelete),
        1
      );
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e));
    }
  };
}
