/**
   This is the main i18n translation definition set for the trust policy editor component.

   It mainly uses the compact `{'English': '日本語'}` format, which is easier to read and edit. The key strings are the English strings, and the values are the Japanese translations.

   Note that complicated or long translations may still use the expanded format if needed:

   ```ts

   someKey: {
      en: 'English message...',
      ja: '日本語のメッセージ...',
    },
    someOtherKey: {
      en: 'English message...',
      ja: '日本語のメッセージ...',
    },
   ```
 */
const TrustPolicyConfigComponentI18n = {
  'Set trust policy': '信頼ポリシーの設定',

  /** help text */
  'Adding a trust policy allows another authorized user to operate as this SAM user.':
    '別のユーザーがこのSAMユーザーとして操作できるように、信頼ポリシーを設定できます。',

  'Add trusted user': '信頼ポリシーにユーザーを追加する',

  'Add trusted user to trust policy': '信頼ポリシーにユーザーを追加する',

  'This trust policy cannot be updated automatically. Please copy the SRN below and add it to the policy manually.':
    '入力済みの信頼ポリシーに自動的にユーザーを追加できません。以下の SRN を追加してください。',

  'Save trust policy?': '信頼ポリシーを保存しますか？',

  'Save trust policy': '信頼ポリシーを保存する',

  'Trusted user type': '信頼するユーザーの種類',

  'Trusted Operator ID': 'オペレーター ID',

  'Trusted user name': 'SAM ユーザー',

  /** button title */
  'Delete trust policy': '信頼ポリシーを削除する',

  Done: 'OK',

  'Copy SRN': 'SRN をコピー',

  /** button title */
  Add: '追加',

  'Trust policy updated successfully': '信頼ポリシーが正常に更新されました',

  'Trust policy deleted successfully': '信頼ポリシーが正常に削除されました',

  'Are you sure you want to delete this trust policy ?': 'この信頼ポリシーを削除してもよろしいですか？',

  syntaxHelp: {
    en: '<!-- English documentation is not yet available for the Trust Policy feature. -->',

    ja: '信頼ポリシー設定の構文は <a href="https://users.soracom.io/ja-jp/docs/switch-user/trust-policy/" target="_blank">ドキュメントをご参照ください</a>',
  },

  /**
   * This text will appear in a modal when saving, to let user confirm the risks.
   */
  saveConfirmationText: {
    en: `<p>This trust policy will grant the trusted user (the operator or SAM user specified in the trust policy) the same permissions that are specified for this SAM user (<code>{{currentSamUser}}</code>).</p>

    <p>Trusted operators may be able to see session history, traffic history, and other sensitive information about this account as it pertains to communications.</p>
`,

    ja: '信頼ポリシーに設定したオペレーターおよび SAM ユーザーに、別途この SAM ユーザー (<code>{{currentSamUser}}</code>) に指定された権限が与えられます。権限によっては、セッション履歴、通信量履歴など、このオペレーターに関する機微な情報を参照できることがあります。',
  },
} as const;

export const i18n = TrustPolicyConfigComponentI18n;
