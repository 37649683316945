import { LegacyAny } from '@soracom/shared/core';

import {
  LegacyBaseSoracomApiService,
  PaginatableService,
  SoracomApiParams,
  SearchQuery,
} from '@user-console/legacy-soracom-api-client';

import { PaginationOptions, ScRelation } from '../components/paginator';
import { InjectList } from '../core/injectable';
import { Role } from '../core/role';
import { LoginUserDataService } from '@soracom/shared/data-access-auth';

export interface CreateUserParams {
  description: string;
}

export interface UpdateUserParams {
  description: string;
}

export interface UpdateUserPasswordParams {
  currentPassword: string;
  newPassword: string;
}

export class UserRolesService implements PaginatableService<Role> {
  static $inject: InjectList = ['BaseSoracomApiService', 'LoginUserDataService'];

  resourcePath = 'roles';
  // @ts-expect-error (legacy code incremental fix)
  userName: string;

  constructor(
    private soracomApi: LegacyBaseSoracomApiService,
    private loginUserDataService: LoginUserDataService,
  ) {}

  get basePath(): string {
    return `/v1/operators/${this.loginUserDataService.getLoginUser()?.operatorId}/users/${this.userName}/${
      this.resourcePath
    }`;
  }

  list(paginationOptions: PaginationOptions, searchQuery?: SearchQuery): Promise<ScRelation<Role>> {
    const apiParams: SoracomApiParams = {
      path: this.basePath,
      query: { ...paginationOptions },
    };

    return this.soracomApi.callApiWithToken(apiParams).then((res: LegacyAny) => {
      return this.soracomApi.generateScRelation<Role>(
        res.data.map((d: LegacyAny) => new Role(d)),
        res,
      );
    });
  }

  attach(roleId: string) {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: this.basePath,
      contentType: 'application/json',
      body: {
        roleId,
      },
    };
    return this.soracomApi.callApiWithToken(apiParams);
  }

  detach(roleId: string) {
    const apiParams: SoracomApiParams = {
      method: 'delete',
      path: `${this.basePath}/${roleId}`,
    };
    return this.soracomApi.callApiWithToken(apiParams);
  }
}
