<app-alerts [alertsManager]="alertsManager"></app-alerts>
<table class="ds-datatable--embedded ds-datatable--compact" [ngClass]="{ 'ds-datatable--loading-refresh': isLoading }">
  <thead>
    <tr>
      <th>{{ 'security.role_details.attached_users.table.column.name' | translate }}</th>
      <th>{{ 'security.role_details.attached_users.table.column.description' | translate }}</th>
    </tr>
  </thead>
  <tbody *ngIf="$any(users)?.length > 0">
    <tr class="--clickable" *ngFor="let user of users" [routerLink]="['/security/users/', user.userName]">
      <td>{{ user.userName }}</td>
      <td>{{ user.description }}</td>
    </tr>
  </tbody>
  <tbody *ngIf="!users || users?.length === 0">
    <tr>
      <td colspan="2" class="--no-data">{{ 'security.role_details.attached_users.table.no_data' | translate }}</td>
    </tr>
  </tbody>
</table>
