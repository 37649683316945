<app-alerts [alertsManager]="alertManager"></app-alerts>
<div class="ds-rows" *ngIf="roleId">
  <dl class="ds-details">
    <dt>{{ 'security.role_details.header' | translate }}</dt>
    <dd>
      <div>{{ roleId }}</div>
    </dd>
    <dt>{{ 'security.role_details.description' | translate }}</dt>
    <dd>
      <ui-edit-in-place [content]="descriptionEditor"></ui-edit-in-place>
    </dd>
  </dl>

  <ui-tabs [content]="tabs">
    <ui-tab [content]="tabsContent[0]">
      <ng-template ui-tab-content>
        <app-role-permissions [role]="role"></app-role-permissions>
      </ng-template>
    </ui-tab>
    <ui-tab [content]="tabsContent[1]">
      <ng-template ui-tab-content>
        <app-role-attached-users [roleId]="roleId"></app-role-attached-users>
      </ng-template>
    </ui-tab>
    <ui-tab [content]="tabsContent[2]">
      <ng-template ui-tab-content>
        <app-role-advance-settings [role]="role"></app-role-advance-settings>
      </ng-template>
    </ui-tab>
  </ui-tabs>
</div>
