<app-alerts [alertsManager]="alertManager"></app-alerts>
<div class="ds-rows">
  <h2>{{ 'security.roles.title' | translate }}</h2>
  <ui-button [content]="createRoleButton"></ui-button>
  <table class="ds-datatable --embedded --compact" [ngClass]="{ 'ds-datatable--loading-refresh': isLoading }">
    <thead>
      <tr>
        <th>{{ 'security.roles.table.column.id' | translate }}</th>
        <th>{{ 'security.roles.table.column.created_at' | translate }}</th>
        <th>{{ 'security.roles.table.column.updated_at' | translate }}</th>
        <th>{{ 'security.roles.table.column.description' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr class="--clickable" *ngFor="let role of roles" [routerLink]="['/security/roles/', role.roleId]">
        <td>{{ role.roleId }}</td>
        <td>{{ formatCreateDateTime($any(role.createDateTime)) }}</td>
        <td>{{ formatUpdateDateTime($any(role.updateDateTime)) }}</td>
        <td>{{ role.description }}</td>
      </tr>
      <tr *ngIf="!isLoading && roles?.length === 0">
        <td>{{ 'security.roles.no_data' | translate }}</td>
      </tr>
    </tbody>
  </table>
</div>
