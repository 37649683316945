import { Component, Input, OnInit, SimpleChanges, inject } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { Role } from '../../../../../app/shared/core/role';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { RoleApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { getLoginUserData } from '@soracom/shared/data-access-auth';

/**
 * Compress json text by removing spaces and new line chars. (but not in values, i.e. not in quotes)
 * This will make it easy to compare before and after changes in json editor,
 * as json editor always returns object and to get json string, we need to use
 * stringify which will always return compressed text!
 */
export function getCompactJsonText(text: string) {
  return text && JSON.stringify(JSON.parse(text));
}

@Component({
  selector: 'app-role-permissions',
  templateUrl: './role-permissions.component.html',
})
export class RolePermissionsComponent implements OnInit {
  // @ts-expect-error (legacy code incremental fix)
  @Input() role: Role;
  isLoading = false;
  text = {
    original: '',
    edited: '',
  };
  data = '';
  alertsManager = new AlertsManager();
  saveButton = new UiButton();
  private roleApi = inject(RoleApiService);

  constructor() {}

  ngOnInit(): void {
    this.saveButton.titleId = 'security.role_details.permissions.save';
    this.saveButton.buttonStyle = 'primary';
    this.saveButton.onClick = this.savePermissions;
    this.saveButton.isDisabled_ƒ = this.isSaveButtonDisabled;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.role?.currentValue) {
      this.activate();
    }
  }

  private activate() {
    if (this.role === undefined || this.role === null) {
      return;
    }
    const permission = getCompactJsonText(this.role.permission);
    this.text.original = permission;
    this.text.edited = permission;
    this.data = permission;
  }

  onDataChanges = (data: string) => {
    this.text.edited = data;
  };

  savePermissions = async () => {
    this.isLoading = true;
    this.alertsManager.clear();
    const role = cloneDeep(this.role);
    role.permission = this.text.edited;

    try {
      await this.roleApi.updateRole({
        roleId: this.role.roleId,
        operatorId: getLoginUserData().operatorId,
        createOrUpdateRoleRequest: { ...role },
      });
      this.text.original = this.text.edited;
      this.role.permission = this.text.edited;
      this.alertsManager.add(Alert.success('security.role_details.permissions.save_success'));
    } catch (e) {
      this.alertsManager.add(Alert.fromApiError(e, 'security.role_details.permissions.common_api_error'));
    } finally {
      this.isLoading = false;
    }
  };

  isSaveButtonDisabled = () => {
    return !(!this.isLoading && this.text.edited && this.text.original !== this.text.edited);
  };
}
