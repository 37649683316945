<div class="ds-rows">
  <div class="ds-page-title ds-cols--stretch">
    <div>
      <h2>
        {{ 'security.default_permissions.title' | translate }}
      </h2>
    </div>
  </div>
  <div class="ds-text--regular">
    {{ 'security.default_permissions.description' | translate }}
  </div>
  <app-permissions-editor [data]="$any(permissionTextData)" [onDataChanges]="$any(onDataChanges)"> </app-permissions-editor>
  <app-alerts [alertsManager]="alertsManager"></app-alerts>
  <ui-button-bar [content]="buttonBar"></ui-button-bar>
</div>
<ng-template #deletePermissionConfirmModalTemplate> </ng-template>
