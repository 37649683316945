<div class="ds-rows">
  <dl class="ds-details">
    @if (user && !user.hasPassword) {
      <dt>{{ 'security.user_details.auth.password_status' | translate }}</dt>
      <dd *ngIf="user && !user.hasPassword">
        <div>{{ 'security.user_details.auth.no_password' | translate }}</div>
        <button type="button" (click)="createPassword()" class="ds-button --plain --hide-label --icon-plus --top-left">
          <span>{{ 'security.user_details.auth.create_password' | translate }}</span>
        </button>
      </dd>
    } @else if (user?.hasPassword) {
      <dt>{{ 'security.user_details.auth.sam_login_url' | translate }}</dt>
      <dd>
        <div class="ds-text --code">
          {{ samLoginUrl }}
        </div>
        <button [appCopyButton]="samLoginUrl" cssClass="--top-left"></button>
      </dd>
      <dt>{{ 'security.user_details.auth.password_status' | translate }}</dt>
      <dd>
        <div>{{ 'security.user_details.auth.configured' | translate }}</div>
        <button type="button" (click)="updatePassword()" class="ds-button --plain --hide-label --icon-edit --top-left">
          <span>{{ 'security.user_details.auth.update_password' | translate }}</span>
        </button>
        <button type="button" (click)="showRemovePasswordConfirm()" class="ds-button --plain --alert --hide-label --icon-delete --top-left">
          <span>{{ 'security.user_details.auth.remove_password' | translate }}</span>
        </button>
      </dd>
    }
  </dl>
</div>

<app-edit-password
  *ngIf="showEditPasswordModal"
  [user]="$any(user)"
  (closeModal)="closeEditPasswordModal($event)"
  [operation]="passwordOperation"
></app-edit-password>

<ng-template #removePasswordConfirmModalTemplate>
  <h5>{{ 'security.user_details.auth.modals.password_delete.message' | translate }}</h5>
</ng-template>
