<div dsDialogContent>
  <dl class="ds-details">
    <dt>{{ 'security.credentials.modals.show.credentials_id' | translate }}</dt>
    <dd>
      <div>{{ data.credentialsId }}</div>
    </dd>

    <dt>{{ 'security.credentials.modals.show.type' | translate }}</dt>
    <dd>
      <div>{{ 'registerCredentials.credentialType.label.' + data.type | translate }}</div>
    </dd>

    <dt>{{ 'security.credentials.modals.show.description' | translate }}</dt>
    <dd>
      <div>{{ data.description }}</div>
    </dd>

    <dt>
      {{ 'security.credentials.modals.show.credentials' | translate }}
      <i
        class="ds-icon--icon-info ds-icon--xsmall"
        [attr.data-ds-texttip]="'security.credentials.modals.show.credentials_notice' | translate"
      ></i>
    </dt>
    <dd>
      <div>
        <pre *ngIf="formattedCredentials()">{{ formattedCredentials() }}</pre>
      </div>
    </dd>
  </dl>
</div>
<footer dsDialogFooter>
  <span>
    <ui-button [content]="closeButton"></ui-button>
  </span>
</footer>
