import { LegacyAny } from '@soracom/shared/core';

import { Injectable } from '@angular/core';
import { CoverageType } from '@foundation/coverage-type';
import { LegacyBaseSoracomApiService, SoracomApiParams } from '@user-console/legacy-soracom-api-client';
import { EventHandler, TargetType } from '../../../../app/shared/event_handlers/event_handler';

@Injectable({
  providedIn: 'root',
})
export class EventHandlersService {
  constructor(private soracomApi: LegacyBaseSoracomApiService) {}

  fetch(target: TargetType, coverageType?: CoverageType): Promise<EventHandler[]> {
    return this.getEventHandlers(target, coverageType).then((res: LegacyAny) =>
      res.data.map((d: LegacyAny) => new EventHandler(d)),
    );
  }

  fetchByImsi(imsi: string): Promise<EventHandler[]> {
    return this.getEventHandlersByImsi(imsi).then((res: LegacyAny) =>
      res.data.map((d: LegacyAny) => new EventHandler(d)),
    );
  }

  create(eventHandler: EventHandler) {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: '/v1/event_handlers',
      contentType: 'application/json',
      body: eventHandler.toJSON(),
    };
    return this.soracomApi.callApiWithToken(apiParams);
  }

  update(eventHandler: EventHandler) {
    const apiParams: SoracomApiParams = {
      method: 'put',
      path: '/v1/event_handlers/' + eventHandler.handlerId,
      contentType: 'application/json',
      body: eventHandler.toJSON(),
    };
    return this.soracomApi.callApiWithToken(apiParams);
  }

  destroy(eventHandler: EventHandler) {
    const apiParams: SoracomApiParams = {
      method: 'delete',
      path: '/v1/event_handlers/' + eventHandler.handlerId,
    };

    return this.soracomApi.callApiWithToken(apiParams);
  }

  ignore(eventHandlerId: string, imsi: string) {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: '/v1/event_handlers/' + eventHandlerId + '/subscribers/' + imsi + '/ignore',
      contentType: 'application/json',
      body: {},
    };

    return this.soracomApi.callApiWithToken(apiParams);
  }

  unsetIgnore(eventHandlerId: string, imsi: string) {
    const apiParams: SoracomApiParams = {
      method: 'delete',
      path: '/v1/event_handlers/' + eventHandlerId + '/subscribers/' + imsi + '/ignore',
    };

    return this.soracomApi.callApiWithToken(apiParams);
  }

  getEventHandlers(target?: string, coverageType?: CoverageType) {
    const apiParams: SoracomApiParams = {
      path: '/v1/event_handlers',
    };

    if (target) {
      apiParams.query = { target };
    }

    return this.soracomApi.callApiWithToken(apiParams, coverageType);
  }

  getEventHandlersByImsi(imsi: string) {
    const apiParams: SoracomApiParams = {
      path: '/v1/event_handlers/subscribers/' + imsi,
    };

    return this.soracomApi.callApiWithToken(apiParams);
  }
}
