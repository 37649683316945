import { Component, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { getLoginUserData } from '@soracom/shared/data-access-auth';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButtonBar } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { getCompactJsonText } from '../roles/role-permissions/role-permissions.component';
import { UserApiService } from '@soracom/shared-ng/soracom-api-ng-client';

@Component({
  selector: 'app-default-sam-permission-config',
  templateUrl: './default-sam-permission-config.component.html',
})
export class DefaultSamPermissionConfigComponent implements OnInit {
  @ViewChild('deletePermissionConfirmModalTemplate', { static: true, read: TemplateRef })
  // @ts-expect-error (legacy code incremental fix)
  deletePermissionConfirmModalTemplate: TemplateRef<any>;
  buttonBar = new UiButtonBar();
  alertsManager = new AlertsManager();
  isLoading = false;
  permissionText = {
    original: '',
    edited: '',
  };
  // @ts-expect-error (legacy code incremental fix)
  permissionTextData: string;
  operatorId = getLoginUserData().operatorId;
  private userService = inject(UserApiService);

  constructor(private uiDsModalService: UiDsModalService) {
    this.buttonBar = UiButtonBar.configure((bar) => {
      bar.leftButtons = [
        UiButton.configure((savePermissionsButton) => {
          savePermissionsButton.titleId = 'security.default_permissions.save';
          savePermissionsButton.buttonStyle = 'primary';
          savePermissionsButton.onClick = this.saveInlinePermissions;
          savePermissionsButton.isDisabled_ƒ = this.isSavePermissionsButtonDisabled;
        }),
        UiButton.configure((resetButton) => {
          resetButton.titleId = 'security.default_permissions.deletePermissions';
          resetButton.iconName = 'icon-delete';
          resetButton.classes = ['--alert'];
          resetButton.onClick = this.deletePermissionsConfirm;
          resetButton.isDisabled_ƒ = () => !this.permissionText.edited || this.permissionText.edited === '{}';
        }),
      ];
    });
  }

  ngOnInit(): void {
    this.fetchDefaultPermissions();
  }

  async fetchDefaultPermissions() {
    try {
      const response = await this.userService.getDefaultPermissions({ operatorId: this.operatorId });
      const defaultPermissions = getCompactJsonText(response.data.defaultPermissions || '');
      this.permissionText.original = defaultPermissions;
      this.permissionText.edited = defaultPermissions;
      this.permissionTextData = defaultPermissions;
    } catch (e) {
      this.alertsManager.clear();
      this.alertsManager.add(Alert.fromApiError(e, 'security.default_permissions.common_api_error'));
    }
  }

  onDataChanges = (data: string) => {
    this.permissionText.edited = data;
  };

  saveInlinePermissions = async () => {
    this.isLoading = true;
    this.alertsManager.clear();
    try {
      await this.userService.updateDefaultPermissions({
        updateDefaultPermissionsRequest: { permissions: this.permissionText.edited },
        operatorId: this.operatorId,
      });
      this.permissionText.original = this.permissionText.edited;
      this.alertsManager.add(Alert.success('security.user_details.permissions.save_success'));
    } catch (e) {
      this.alertsManager.add(Alert.fromApiError(e, 'security.role_details.permissions.common_api_error'));
    } finally {
      this.isLoading = false;
    }
  };

  deletePermissionsConfirm = () => {
    this.uiDsModalService.openConfirmModal(this.deletePermissionConfirmModalTemplate, {
      title: 'security.default_permissions.deletePermissions',
      classes: ['revoke-authkey'],
      okButton: (button) => {
        button.titleId = 'common.delete';
        button.buttonStyle = 'danger';
        button.classes = ['delete-permissions-confirm-button'];
      },
      onOkClick: this.deletePermissions,
      modalStyle: 'delete',
      subtitle: 'security.default_permissions.connfirmDeletePermissions',
      showLoadingProgressOnOkClick: true,
    });
  };

  deletePermissions = async () => {
    this.permissionText.edited = '{}';
    await this.saveInlinePermissions();
    this.permissionText.original = this.permissionText.edited;
    this.permissionTextData = '{}';
  };

  isSavePermissionsButtonDisabled = () => {
    return !(
      !this.isLoading &&
      this.permissionText.edited &&
      this.permissionText.original !== this.permissionText.edited
    );
  };
}
