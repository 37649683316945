import { LegacyAny } from '@soracom/shared/core';

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RolesService } from '../../../../../app/shared/security/modals/roles.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiEditInPlace } from '../../../soracom-ui/ui-edit-in-place/UiEditInPlace';
import { UiTab } from '../../../soracom-ui/ui-tabs/UiTab';
import { UiTabs } from '../../../soracom-ui/ui-tabs/UiTabs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-role-details',
  templateUrl: './role-details.component.html',
})
export class RoleDetailsComponent implements OnChanges {
  // @ts-expect-error (legacy code incremental fix)
  @Input() roleId: string;
  alertManager = new AlertsManager();
  descriptionEditor = new UiEditInPlace();
  role: LegacyAny;

  tabs = new UiTabs();
  tabsContent = [
    UiTab.configure((uiTab) => {
      uiTab.labelId = 'security.role_details.tab.permissions';
    }),
    UiTab.configure((uiTab) => {
      uiTab.labelId = 'security.role_details.tab.users';
    }),
    UiTab.configure((uiTab) => {
      uiTab.labelId = 'security.role_details.tab.advanced_settings';
      uiTab.spacedTabContent = false;
    }),
  ];

  constructor(private rolesService: RolesService, private translate: TranslateService) {
    this.tabs.tabIdPrefix = 'tab-role-details';
    this.descriptionEditor.placeholderText = this.translate.instant('security.role_details.description');
    this.descriptionEditor.onSave.pipe(takeUntilDestroyed()).subscribe(this.saveDescription);
  }

  async ngOnChanges(change: SimpleChanges) {
    if (change?.roleId?.currentValue !== change?.roleId?.previousValue) {
      await this.fetchRoleDetails();
      this.descriptionEditor.text = this.role.description || '';
    }
  }

  async fetchRoleDetails() {
    if (!this.roleId) {
      return;
    }

    this.alertManager.clear();
    try {
      this.role = await this.rolesService.get(this.roleId);
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e));
    }
  }

  saveDescription = async (description: LegacyAny) => {
    this.alertManager.clear();
    try {
      await this.rolesService.update(this.roleId, {
        ...this.role,
        description,
      });
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e));
    }
  };
}
