<app-alerts [alertsManager]="alertManager"></app-alerts>
<div class="ds-rows">
  <h2>{{ 'security.users.title' | translate }}</h2>
  <ui-button [content]="addUserButton"></ui-button>
  <div class="--responsive">
    <table class="ds-datatable --embedded --compact" [ngClass]="{ 'ds-datatable--loading-refresh': isLoading }">
      <thead>
        <tr>
          <th>{{ 'security.users.table.column.name' | translate }}</th>
          <th>{{ 'security.users.table.column.description' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="--clickable" *ngFor="let user of users" [routerLink]="['/security/users/', user.userName]">
          <td>{{ user.userName }}</td>
          <td>{{ user.description }}</td>
        </tr>
        <tr *ngIf="!isLoading && users?.length === 0">
          <td>{{ 'security.users.no_data' | translate }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>